import React, { useState } from 'react'
import './Forms.css'
import api from '../../services/api'
import Header from '../../components/Header'

const Home = () => {
  const [dataContratacao, setDataContratacao] = useState('')
  const [dataAniversario, setDataAniversario] = useState('')
  const [Unidade, setUnidade] = useState('')
  const [nome, setNome] = useState('')
  const [cpf, setCpf] = useState('')
  const [email, setEmail] = useState('')
  const [telefone, setTelefone] = useState('')
  const [nacionalidade, setNacionalidade] = useState('')
  const [genero, setGenero] = useState('')
  const [tipoDeContrato, setTipoDeContrato] = useState('')
  const [nivelDeFormacao, setNivelDeFormacao] = useState('')
  const [qualificacao, setQualificacao] = useState('')
  const [empresa, setEmpresa] = useState('')
  const [cargo, setCargo] = useState('')
  const [faixaSalarial, setFaixaSalarial] = useState('')
  const [areaDeAtuacao, setAreaDeAtuacao] = useState('')
  //dataContratacao cpf email telefone nacionalidade genero tipoDeContrato nivelDeFormacao empresa cargo faixaSalarial

  const handlersSubmit = async e => {
    const dados = [
      dataContratacao,
      Unidade,
      nome,
      cpf,
      email,
      telefone,
      nacionalidade,
      genero,
      tipoDeContrato,
      nivelDeFormacao,
      qualificacao,
      empresa,
      areaDeAtuacao,
      cargo,
      faixaSalarial,
      dataAniversario
    ]
    e.preventDefault()

    try {
      const resp = await api.post(`/contratados/${Unidade}`, dados)
      console.log(resp)
      alert('Salvo com sucesso! :D')
      window.location.reload()
    } catch (error) {
      const err = { message: 'Algo deu errado!' }
      alert(err.message)
    }
  }
  return (
    <>
      <Header />
      <div id="body" className="container">
        <div id="contratacao">
          <form onSubmit={handlersSubmit} className="form">
            {/* Data de contratacao */}
            <div className="field">
              <label htmlFor="text" className="">
                {' '}
                Data da Contratação<span className="required">*</span>{' '}
              </label>
              <input
                required
                className="borda"
                type="date"
                id="dataContratacao"
                placeholder="DD/MM/YYYY"
                value={dataContratacao}
                onChange={e => setDataContratacao(e.target.value)}
              />
            </div>
            {/* api para consultar as unidade existente * */}
            <div className="field">
              <label htmlFor="">
                Unidade<span className="required">*</span>
              </label>

              <select
                required
                id="Categoria"
                className="borda"
                name="Categoria"
                value={Unidade}
                onChange={e => setUnidade(e.target.value)}
              >
                <option> </option>
                <option value="	021NOSSASHISTÓRIAS">021NOSSASHISTÓRIAS</option>
                <option value="	A VEZ DAS COMUNIDADES">A VEZ DAS COMUNIDADES</option>
                <option value="	AACCS">AACCS</option>
                <option value="	ABC MUNDO NOVO">ABC MUNDO NOVO</option>
                <option value="	ABFB ALIANÇA BAYEUX FRANCO BRASILEIRA">ABFB ALIANÇA BAYEUX FRANCO BRASILEIRA</option>
                <option value="	ABRACE COM AMOR">ABRACE COM AMOR</option>
                <option value="	ABRAÇO PERIFÉRICO">ABRAÇO PERIFÉRICO</option>
                <option value="	ABRIGO BENEFICENTE BRACOS ABERTOS ( ABBA)">ABRIGO BENEFICENTE BRACOS ABERTOS ( ABBA)</option>
                <option value="	AÇÃO SOCIAL ABAS DO BEM">AÇÃO SOCIAL ABAS DO BEM</option>
                <option value="	ACAO SOCIAL ARQUIDIOCESANA DE PALMAS">ACAO SOCIAL ARQUIDIOCESANA DE PALMAS</option>
                <option value="	ACIESP">ACIESP</option>
                <option value="	ACMDQ - ASSOCIACAO COLETIVA MULHERES DA QUEBRADA">ACMDQ - ASSOCIACAO COLETIVA MULHERES DA QUEBRADA</option>
                <option value="	ACSEM ASSOCIAÇÃO COMUNITÁRIA DO CONJUNTO SÍTIO ESTRELA -MESSEJANA">ACSEM ASSOCIAÇÃO COMUNITÁRIA DO CONJUNTO SÍTIO ESTRELA -MESSEJANA</option>
                <option value="	ACTUM BRAZIL">ACTUM BRAZIL</option>
                <option value="	ADOTE UM(A) EXPLORER">ADOTE UM(A) EXPLORER</option>
                <option value="	AFA PROJETO AMAR A FAVELA">AFA PROJETO AMAR A FAVELA</option>
                <option value="	ÁFRICA BASQUETE">ÁFRICA BASQUETE</option>
                <option value="	AGECOM- PROJETO GERAÇÃO DA CHICO">AGECOM- PROJETO GERAÇÃO DA CHICO</option>
                <option value="	AGENCIA FACILITADORA PARA INVESTIMENTOS CULTURAIS">AGENCIA FACILITADORA PARA INVESTIMENTOS CULTURAIS</option>
                <option value="	AGENTES DE TRANSFORMAÇÃO ASSOCIAÇÃO CIVIL">AGENTES DE TRANSFORMAÇÃO ASSOCIAÇÃO CIVIL</option>
                <option value="	AMARALIMPA">AMARALIMPA</option>
                <option value="	AMIGO SOLIDARIO">AMIGO SOLIDARIO</option>
                <option value="	AMIGOS DA SOLIDARIEDADE RJ">AMIGOS DA SOLIDARIEDADE RJ</option>
                <option value="	AMIGOS SOLIDÁRIOS DE PARNAÍBA">AMIGOS SOLIDÁRIOS DE PARNAÍBA</option>
                <option value="	AMIGUINHOS DE JESUS CURIO">AMIGUINHOS DE JESUS CURIO</option>
                <option value="	AMIRES-ASSOCIACAO MISSAO RESPLANDECER">AMIRES-ASSOCIACAO MISSAO RESPLANDECER</option>
                <option value="	ANA PAULA OLIVEIRA SOUZA">ANA PAULA OLIVEIRA SOUZA</option>
                <option value="	ANGELS">ANGELS</option>
                <option value="	ANGU DAS ARTES">ANGU DAS ARTES</option>
                <option value="	ANJOS DE ASAS NO MUNDO AZUL">ANJOS DE ASAS NO MUNDO AZUL</option>
                <option value="	APAT - ASSOCIAÇÃO DE PAIS E AMIGOS DOS TENISTAS DE OURO BRANCO">APAT - ASSOCIAÇÃO DE PAIS E AMIGOS DOS TENISTAS DE OURO BRANCO</option>
                <option value="	ARC -ASSOCIAÇÃO RECREATIVA E CULTURAL DO JARDIM TREZE DE MAIO">ARC -ASSOCIAÇÃO RECREATIVA E CULTURAL DO JARDIM TREZE DE MAIO</option>
                <option value="	ARCANJO GABRIEL">ARCANJO GABRIEL</option>
                <option value="	ARTE NAS COMUNIDADES">ARTE NAS COMUNIDADES</option>
                <option value="	ASCOMVE">ASCOMVE</option>
                <option value="	ASMIE-BR - ASSOCIACAO DE MINISTROS E IGREJAS EVANGELICAS DO BRASIL CONSELHO DE MINISTERIOS E ACAO SOCIAL">ASMIE-BR - ASSOCIACAO DE MINISTROS E IGREJAS EVANGELICAS DO BRASIL CONSELHO DE MINISTERIOS E ACAO SOCIAL</option>
                <option value="	ASS DOS MORAD DOS B FRUTILANDIA I E II E FULO DO MATO">ASS DOS MORAD DOS B FRUTILANDIA I E II E FULO DO MATO</option>
                <option value="	ASSOC. C. RAIO DE CRISTAL">ASSOC. C. RAIO DE CRISTAL</option>
                <option value="	ASSOCIACAO ACAO SOCIAL JESUS DE NAZARE">ASSOCIACAO ACAO SOCIAL JESUS DE NAZARE</option>
                <option value="	ASSOCIACAO ACOES COM AMOR">ASSOCIACAO ACOES COM AMOR</option>
                <option value="	ASSOCIACAO ACOLHER MACAPA">ASSOCIACAO ACOLHER MACAPA</option>
                <option value="	ASSOCIAÇÃO ÁGUIA">ASSOCIAÇÃO ÁGUIA</option>
                <option value="	ASSOCIAÇÃO ALÇANDO VELAS">ASSOCIAÇÃO ALÇANDO VELAS</option>
                <option value="	ASSOCIAÇÃO AMIGOS DE GEGÊ FOS MORADORES DA GAMBOA DE BAIXO">ASSOCIAÇÃO AMIGOS DE GEGÊ FOS MORADORES DA GAMBOA DE BAIXO</option>
                <option value="	ASSOCIAÇÃO AMIGOS DO BAIRRO E DO VOVO">ASSOCIAÇÃO AMIGOS DO BAIRRO E DO VOVO</option>
                <option value="	ASSOCIAÇÃO AMIGOS DO NOEL">ASSOCIAÇÃO AMIGOS DO NOEL</option>
                <option value="	ASSOCIAÇÃO AMOR E VIDA">ASSOCIAÇÃO AMOR E VIDA</option>
                <option value="	ASSOCIACAO AMOR PELA COMUNIDADE DO COQUE">ASSOCIACAO AMOR PELA COMUNIDADE DO COQUE</option>
                <option value="	ASSOCIAÇÃO ANAJÔ">ASSOCIAÇÃO ANAJÔ</option>
                <option value="	ASSOCIACAO ANJO RAFAEL">ASSOCIACAO ANJO RAFAEL</option>
                <option value="	ASSOCIACAO APRENDER CULTURA">ASSOCIACAO APRENDER CULTURA</option>
                <option value="	ASSOCIAÇÃO ARTE DE AMAR">ASSOCIAÇÃO ARTE DE AMAR</option>
                <option value="	ASSOCIACAO ARTE E ACORDES">ASSOCIACAO ARTE E ACORDES</option>
                <option value="	ASSOCIACAO ARTE E CULTURA EM LINGUA PORTUGUESA">ASSOCIACAO ARTE E CULTURA EM LINGUA PORTUGUESA</option>
                <option value="	ASSOCIACAO ARTE PELA PAZ">ASSOCIACAO ARTE PELA PAZ</option>
                <option value="	ASSOCIACAO ARTE SEM FRONTEIRA">ASSOCIACAO ARTE SEM FRONTEIRA</option>
                <option value="	ASSOCIACAO BALLET MANGUINHOS">ASSOCIACAO BALLET MANGUINHOS</option>
                <option value="	ASSOCIAÇÃO BENEFICEENTE ANJOS DE OURO">ASSOCIAÇÃO BENEFICEENTE ANJOS DE OURO</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE A CRIANÇA E A FAMÍLIA CARENTE">ASSOCIAÇÃO BENEFICENTE A CRIANÇA E A FAMÍLIA CARENTE</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE AÇÃO E AMOR">ASSOCIAÇÃO BENEFICENTE AÇÃO E AMOR</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE AMIGAS SOLIDÁRIAS">ASSOCIAÇÃO BENEFICENTE AMIGAS SOLIDÁRIAS</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE AMIGOS PELA CARIDADE">ASSOCIAÇÃO BENEFICENTE AMIGOS PELA CARIDADE</option>
                <option value="	ASSOCIACAO BENEFICENTE CULTURAL EDUCATIVA DAS ASSOCIACOES">ASSOCIACAO BENEFICENTE CULTURAL EDUCATIVA DAS ASSOCIACOES</option>
                <option value="	ASSOCIACAO BENEFICENTE CULTURAL UGO MEREGALLI">ASSOCIACAO BENEFICENTE CULTURAL UGO MEREGALLI</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE DA CRIANÇA E DO ADOLESCENTE">ASSOCIAÇÃO BENEFICENTE DA CRIANÇA E DO ADOLESCENTE</option>
                <option value="	ASSOCIACAO BENEFICENTE DE ITAPORE">ASSOCIACAO BENEFICENTE DE ITAPORE</option>
                <option value="	ASSOCIACAO BENEFICENTE DOE AMOR - ABDA/MCZ">ASSOCIACAO BENEFICENTE DOE AMOR - ABDA/MCZ</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE EDUCACIONAL CORUJINHAS">ASSOCIAÇÃO BENEFICENTE EDUCACIONAL CORUJINHAS</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE EDUCACIONAL TABERNACULO">ASSOCIAÇÃO BENEFICENTE EDUCACIONAL TABERNACULO</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE EVANGÉLICA DA FLORESTA IMPERIAL DE NOVO HAMBURGO">ASSOCIAÇÃO BENEFICENTE EVANGÉLICA DA FLORESTA IMPERIAL DE NOVO HAMBURGO</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE HEBROM (NÚCLEO TEFÉ AM)">ASSOCIAÇÃO BENEFICENTE HEBROM (NÚCLEO TEFÉ AM)</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE RAUL DAS TINTAS">ASSOCIAÇÃO BENEFICENTE RAUL DAS TINTAS</option>
                <option value="	ASSOCIACAO BENEFICENTE UNIAO DO PARANA">ASSOCIACAO BENEFICENTE UNIAO DO PARANA</option>
                <option value="	ASSOCIAÇÃO BENEFICENTE VIVENDA DA CRIANÇA">ASSOCIAÇÃO BENEFICENTE VIVENDA DA CRIANÇA</option>
                <option value="	ASSOCIAÇÃO BENEFICIENTE ARCA DA VITORIA">ASSOCIAÇÃO BENEFICIENTE ARCA DA VITORIA</option>
                <option value="	ASSOCIACAO BENEFICIENTE ROSA DALIA">ASSOCIACAO BENEFICIENTE ROSA DALIA</option>
                <option value="	ASSOCIAÇÃO BOM SAMARITANO">ASSOCIAÇÃO BOM SAMARITANO</option>
                <option value="	ASSOCIAÇÃO BRASILEIRA FEMININA DE BODYBOARDING">ASSOCIAÇÃO BRASILEIRA FEMININA DE BODYBOARDING</option>
                <option value="	ASSOCIAÇÃO BRASILEIRA MISSÃO E ESPERANÇA (ASBRAME)">ASSOCIAÇÃO BRASILEIRA MISSÃO E ESPERANÇA (ASBRAME)</option>
                <option value="	ASSOCIACAO CAMINHANDO PARA MAIS UM SONHO">ASSOCIACAO CAMINHANDO PARA MAIS UM SONHO</option>
                <option value="	ASSOCIAÇÃO CASA DE GENTIL CULTURAS E CONVÍVIOS">ASSOCIAÇÃO CASA DE GENTIL CULTURAS E CONVÍVIOS</option>
                <option value="	ASSOCIAÇÃO CASA HACKER">ASSOCIAÇÃO CASA HACKER</option>
                <option value="	ASSOCIAÇÃO CASA UP">ASSOCIAÇÃO CASA UP</option>
                <option value="	ASSOCIACAO CEARENSE DE INCLUSAO E ASSISTENCIA SOCIAL">ASSOCIACAO CEARENSE DE INCLUSAO E ASSISTENCIA SOCIAL</option>
                <option value="	ASSOCIACAO CENTRO DE APOIO ACOLHER">ASSOCIACAO CENTRO DE APOIO ACOLHER</option>
                <option value="	ASSOCIACAO CENTRO DE EDUCACAO INFANTIL JOAO PAULO II">ASSOCIACAO CENTRO DE EDUCACAO INFANTIL JOAO PAULO II</option>
                <option value="	ASSOCIAÇÃO CHÁCARA DO PRIMEIRO">ASSOCIAÇÃO CHÁCARA DO PRIMEIRO</option>
                <option value="	ASSOCIAÇÃO CIDADANIA SOCIAL E SUSTENTABILIDADE">ASSOCIAÇÃO CIDADANIA SOCIAL E SUSTENTABILIDADE</option>
                <option value="	ASSOCIAÇÃO CIDADANIA* DESPORTO* LAZER E CULTURA- CELC">ASSOCIAÇÃO CIDADANIA* DESPORTO* LAZER E CULTURA- CELC</option>
                <option value="	ASSOCIAÇAO CIRCUITO INCLUSAO">ASSOCIAÇAO CIRCUITO INCLUSAO</option>
                <option value="	ASSOCIAÇÃO CIVIL PROJETO JUVENTUDE ESPERANÇA DO AMANHÃ">ASSOCIAÇÃO CIVIL PROJETO JUVENTUDE ESPERANÇA DO AMANHÃ</option>
                <option value="	ASSOCIACAO CLUBE DE MAES DO LAR PEROLAS DE CRISTO">ASSOCIACAO CLUBE DE MAES DO LAR PEROLAS DE CRISTO</option>
                <option value="	ASSOCIACAO CLUBINHO AMIGOS E AMIGAS">ASSOCIACAO CLUBINHO AMIGOS E AMIGAS</option>
                <option value="	ASSOCIAÇÃO COLETIVO MOTIRÕ">ASSOCIAÇÃO COLETIVO MOTIRÕ</option>
                <option value="	ASSOCIACAO COLETIVO VILA BEIRA MAR">ASSOCIACAO COLETIVO VILA BEIRA MAR</option>
                <option value="	ASSOCIACAO COMUNIDADE ESPORTIVA SHALKE-XII">ASSOCIACAO COMUNIDADE ESPORTIVA SHALKE-XII</option>
                <option value="	ASSOCIAÇÃO COMUNIDADE SOLIDÁRIA DO JARDIM SÃO BERNARDO">ASSOCIAÇÃO COMUNIDADE SOLIDÁRIA DO JARDIM SÃO BERNARDO</option>
                <option value="	ASSOCIACAO COMUNITARIA A VITORIA E NOSSA">ASSOCIACAO COMUNITARIA A VITORIA E NOSSA</option>
                <option value="	ASSOCIAÇÃO COMUNITÁRIA DE AGENTES AMBIENTAIS">ASSOCIAÇÃO COMUNITÁRIA DE AGENTES AMBIENTAIS</option>
                <option value="	ASSOCIACAO COMUNITARIA DE DOIS UNIDOS E ADJACENCIAS">ASSOCIACAO COMUNITARIA DE DOIS UNIDOS E ADJACENCIAS</option>
                <option value="	ASSOCIACAO COMUNITARIA DOS MORADORES DO PARQUE NAZARE">ASSOCIACAO COMUNITARIA DOS MORADORES DO PARQUE NAZARE</option>
                <option value="	ASSOCIACAO COMUNITARIA DOS MORADORES E AMIGOS DO TRAPICHE DA BARRA">ASSOCIACAO COMUNITARIA DOS MORADORES E AMIGOS DO TRAPICHE DA BARRA</option>
                <option value="	ASSOCIACAO COMUNITARIA E CRECHE DAS FAMILIAS CARENTES DA VILA VITORIA">ASSOCIACAO COMUNITARIA E CRECHE DAS FAMILIAS CARENTES DA VILA VITORIA</option>
                <option value="	ASSOCIAÇÃO COMUNITARIA ESPERANÇA">ASSOCIAÇÃO COMUNITARIA ESPERANÇA</option>
                <option value="	ASSOCIACAO COMUNITARIA INDIO PIRAGIBE ILHA DO BISPO">ASSOCIACAO COMUNITARIA INDIO PIRAGIBE ILHA DO BISPO</option>
                <option value="	ASSOCIACAO COMUNITARIA JOSE DOS SANTOS">ASSOCIACAO COMUNITARIA JOSE DOS SANTOS</option>
                <option value="	ASSOCIAÇÃO COMUNITÁRIA MARIA JOÃO DE DEUS">ASSOCIAÇÃO COMUNITÁRIA MARIA JOÃO DE DEUS</option>
                <option value="	ASSOCIAÇÃO COMUNITÁRIA NOSSA SENHORA DO CARMO">ASSOCIAÇÃO COMUNITÁRIA NOSSA SENHORA DO CARMO</option>
                <option value="	ASSOCIACAO COMUNITARIA SANTOS DO JARDIM DAS OLIVEIRAS">ASSOCIACAO COMUNITARIA SANTOS DO JARDIM DAS OLIVEIRAS</option>
                <option value="	ASSOCIAÇÃO COMUNITARIA TRANSFORMAR">ASSOCIAÇÃO COMUNITARIA TRANSFORMAR</option>
                <option value="	ASSOCIAÇÃO COMUNITÁRIA UNIÃO POTENGI">ASSOCIAÇÃO COMUNITÁRIA UNIÃO POTENGI</option>
                <option value="	ASSOCIACAO CRECHE ESPERANCA">ASSOCIACAO CRECHE ESPERANCA</option>
                <option value="	ASSOCIACAO CRESCER, APRENDER E SE DIVERTIR - ACAD">ASSOCIACAO CRESCER, APRENDER E SE DIVERTIR - ACAD</option>
                <option value="	ASSOCIAÇÃO CRIANÇA FELIZ DE SOROCABA">ASSOCIAÇÃO CRIANÇA FELIZ DE SOROCABA</option>
                <option value="	ASSOCIAÇÃO CULTURAL ARAUTOS DO GUETO">ASSOCIAÇÃO CULTURAL ARAUTOS DO GUETO</option>
                <option value="	ASSOCIACAO CULTURAL BELEZA INTERIOR">ASSOCIACAO CULTURAL BELEZA INTERIOR</option>
                <option value="	ASSOCIAÇÃO CULTURAL CASA DO BECO">ASSOCIAÇÃO CULTURAL CASA DO BECO</option>
                <option value="	ASSOCIAÇÃO CULTURAL DE EDUCAÇÃO SOCIAL E ARTÍSTICA - ACESA">ASSOCIAÇÃO CULTURAL DE EDUCAÇÃO SOCIAL E ARTÍSTICA - ACESA</option>
                <option value="	ASSOCIAÇÃO CULTURAL DESPORTIVA SAPUCAIENSE DE CAPOEIRA">ASSOCIAÇÃO CULTURAL DESPORTIVA SAPUCAIENSE DE CAPOEIRA</option>
                <option value="	ASSOCIAÇÃO CULTURAL E EDUCACIONAL VIOLETA FELIZ">ASSOCIAÇÃO CULTURAL E EDUCACIONAL VIOLETA FELIZ</option>
                <option value="	ASSOCIACAO CULTURAL E ESPORTIVA AQUIDABA - ACESA">ASSOCIACAO CULTURAL E ESPORTIVA AQUIDABA - ACESA</option>
                <option value="	ASSOCIAÇÃO CULTURAL E SOCIAL VILA NOVA">ASSOCIAÇÃO CULTURAL E SOCIAL VILA NOVA</option>
                <option value="	ASSOCIAÇÃO CULTURAL EDUCACIONAL E ESPORTIVA NA PONTA DOS PÉS">ASSOCIAÇÃO CULTURAL EDUCACIONAL E ESPORTIVA NA PONTA DOS PÉS</option>
                <option value="	ASSOCIAÇÃO CULTURAL ESPORTIVA SOCIAL AMIGOS - ACESA">ASSOCIAÇÃO CULTURAL ESPORTIVA SOCIAL AMIGOS - ACESA</option>
                <option value="	ASSOCIAÇÃO CULTURAL PISADA DO SERTÃO">ASSOCIAÇÃO CULTURAL PISADA DO SERTÃO</option>
                <option value="	ASSOCIACAO DAS DONAS DE CASA DA SALINA DO SACAVEM">ASSOCIACAO DAS DONAS DE CASA DA SALINA DO SACAVEM</option>
                <option value="	ASSOCIACAO DAS FAMILIAS DE ANJOS DO ESTADO DE ALAGOAS">ASSOCIACAO DAS FAMILIAS DE ANJOS DO ESTADO DE ALAGOAS</option>
                <option value="	ASSOCIAÇÃO DAS MULHERES DE NAZARÉ DA MATA">ASSOCIAÇÃO DAS MULHERES DE NAZARÉ DA MATA</option>
                <option value="	ASSOCIACAO DAS OBRAS PAVONIANAS DE ASSISTENCIA">ASSOCIACAO DAS OBRAS PAVONIANAS DE ASSISTENCIA</option>
                <option value="	ASSOCIACAO DAS PESSOAS COM DEFICIENCIA BOM VIVER">ASSOCIACAO DAS PESSOAS COM DEFICIENCIA BOM VIVER</option>
                <option value="	ASSOCIAÇÃO DE  AMIGOS  DOS PORTADORES DE DOENÇAS GRAVES E DE CRIANÇAS EM VULNERABILIDADE SOCIAL">ASSOCIAÇÃO DE  AMIGOS  DOS PORTADORES DE DOENÇAS GRAVES E DE CRIANÇAS EM VULNERABILIDADE SOCIAL</option>
                <option value="	ASSOCIAÇÃO DE APOIO A MÃES DE ESPECIAIS- INSTITUTO AME">ASSOCIAÇÃO DE APOIO A MÃES DE ESPECIAIS- INSTITUTO AME</option>
                <option value="	ASSOCIAÇÃO DE APOIO ÀS FAMÍLIAS VULNERÁVEIS">ASSOCIAÇÃO DE APOIO ÀS FAMÍLIAS VULNERÁVEIS</option>
                <option value="	ASSOCIACAO DE ARTE E ARTESANATO VALE DA ESPERANCA ''AAAVE''">ASSOCIACAO DE ARTE E ARTESANATO VALE DA ESPERANCA ''AAAVE''</option>
                <option value="	ASSOCIAÇAO DE ARTESANATO E APICULTURA DOS POVOADOS TIGRE E JUNÇA">ASSOCIAÇAO DE ARTESANATO E APICULTURA DOS POVOADOS TIGRE E JUNÇA</option>
                <option value="	ASSOCIACAO DE BENEFICENCIA A DEPENDENTES QUIMICOS SAO JOSE - ABDEQUI">ASSOCIACAO DE BENEFICENCIA A DEPENDENTES QUIMICOS SAO JOSE - ABDEQUI</option>
                <option value="	ASSOCIAÇÃO DE CAPOEIRA MULEKI É TU">ASSOCIAÇÃO DE CAPOEIRA MULEKI É TU</option>
                <option value="	ASSOCIAÇÃO DE CULTURA E ARTES CASA DE BAMBAS">ASSOCIAÇÃO DE CULTURA E ARTES CASA DE BAMBAS</option>
                <option value="	ASSOCIACAO DE DESENVOLVIMENTO MENINO CRESCER">ASSOCIACAO DE DESENVOLVIMENTO MENINO CRESCER</option>
                <option value="	ASSOCIAÇÃO DE DESENVOLVIMENTO SUSTENTÁVEL TIO HENRIQUE E CIA">ASSOCIAÇÃO DE DESENVOLVIMENTO SUSTENTÁVEL TIO HENRIQUE E CIA</option>
                <option value="	ASSOCIAÇÃO DE EDUCAÇÃO E ARTE">ASSOCIAÇÃO DE EDUCAÇÃO E ARTE</option>
                <option value="	ASSOCIACAO DE ESPORTE, ARTE E EDUCACAO DO BAIRRO NOVO HORIZONTE">ASSOCIACAO DE ESPORTE, ARTE E EDUCACAO DO BAIRRO NOVO HORIZONTE</option>
                <option value="	ASSOCIAÇÃO DE LAZER ESPORTE EDUCAÇÃO E CULTURA - LEEC">ASSOCIAÇÃO DE LAZER ESPORTE EDUCAÇÃO E CULTURA - LEEC</option>
                <option value="	ASSOCIACAO DE MAES E AMIGOS DOS AUTISTAS DE UNAI - AMAA">ASSOCIACAO DE MAES E AMIGOS DOS AUTISTAS DE UNAI - AMAA</option>
                <option value="	ASSOCIAÇÃO DE MORADORES DA RUA TRAVASSOS">ASSOCIAÇÃO DE MORADORES DA RUA TRAVASSOS</option>
                <option value="	ASSOCIACAO DE MORADORES DA VILA FELICIDADE">ASSOCIACAO DE MORADORES DA VILA FELICIDADE</option>
                <option value="	ASSOCIACAO DE MORADORES DA VILA SAO FRANCISCO E ADJACENCIAS POA-SP">ASSOCIACAO DE MORADORES DA VILA SAO FRANCISCO E ADJACENCIAS POA-SP</option>
                <option value="	ASSOCIAÇÃO DE MORADORES DE MONTE CRISTO">ASSOCIAÇÃO DE MORADORES DE MONTE CRISTO</option>
                <option value="	ASSOCIAÇÃO DE MORADORES DO BAIRRO ALVORADA">ASSOCIAÇÃO DE MORADORES DO BAIRRO ALVORADA</option>
                <option value="	ASSOCIAÇÃO DE MORADORES DO JARDIM NEMARI E VILA MARTINS">ASSOCIAÇÃO DE MORADORES DO JARDIM NEMARI E VILA MARTINS</option>
                <option value="	ASSOCIACAO DE MORADORES FRANCISCO NUNES">ASSOCIACAO DE MORADORES FRANCISCO NUNES</option>
                <option value="	ASSOCIAÇÃO DE MORADORES PARIPE EM MOVIMENTO">ASSOCIAÇÃO DE MORADORES PARIPE EM MOVIMENTO</option>
                <option value="	ASSOCIAÇÃO DE MULHERES DE ATITUDE E COMPROMISSO SOCIAL">ASSOCIAÇÃO DE MULHERES DE ATITUDE E COMPROMISSO SOCIAL</option>
                <option value="	ASSOCIAÇAO DE MULHERES EMPODERADAS DO MONTE CRISTO">ASSOCIAÇAO DE MULHERES EMPODERADAS DO MONTE CRISTO</option>
                <option value="	ASSOCIAÇÃO DE MULHERES NEGRAS DO ACRE E SEUS APOIADORES">ASSOCIAÇÃO DE MULHERES NEGRAS DO ACRE E SEUS APOIADORES</option>
                <option value="	ASSOCIACAO DE ORIENTACAO PARA EMPREGO, EDUCACAO E SAUDE - ORIGEM/AMORIM">ASSOCIACAO DE ORIENTACAO PARA EMPREGO, EDUCACAO E SAUDE - ORIGEM/AMORIM</option>
                <option value="	ASSOCIAÇÃO DE PROTEÇÃO À INFÂNCIA VOVÔ VITORINO">ASSOCIAÇÃO DE PROTEÇÃO À INFÂNCIA VOVÔ VITORINO</option>
                <option value="	ASSOCIACAO DE REINTEGRACAO MAIS VIDA">ASSOCIACAO DE REINTEGRACAO MAIS VIDA</option>
                <option value="	ASSOCIAÇÃO DE SURF DA PRAIA DE MIAMI E AREIA PRETA">ASSOCIAÇÃO DE SURF DA PRAIA DE MIAMI E AREIA PRETA</option>
                <option value="	ASSOCIAÇÃO DE TEATRO ARTES E YOGA">ASSOCIAÇÃO DE TEATRO ARTES E YOGA</option>
                <option value="	ASSOCIAÇÃO DEFENSORA DA ILHA DE JOANEIRO">ASSOCIAÇÃO DEFENSORA DA ILHA DE JOANEIRO</option>
                <option value="	ASSOCIAÇÃO DEFICIÊNCIA SUPERANDO LIMITES - ADESUL">ASSOCIAÇÃO DEFICIÊNCIA SUPERANDO LIMITES - ADESUL</option>
                <option value="	ASSOCIAÇÃO DESPERTAR SABEDORIA NO SOL NASCETE">ASSOCIAÇÃO DESPERTAR SABEDORIA NO SOL NASCETE</option>
                <option value="	ASSOCIAÇÃO DESPORTIVA E CULTURAL BRAZIL FOOTBALL CLUB">ASSOCIAÇÃO DESPORTIVA E CULTURAL BRAZIL FOOTBALL CLUB</option>
                <option value="	ASSOCIAÇÃO DESPORTIVA E SOCIO CULTURAL CELEIRO DE BAMBAS">ASSOCIAÇÃO DESPORTIVA E SOCIO CULTURAL CELEIRO DE BAMBAS</option>
                <option value="	ASSOCIACAO DIAMANTES NA COZINHA">ASSOCIACAO DIAMANTES NA COZINHA</option>
                <option value="	ASSOCIAÇÃO DO CALVÁRIO">ASSOCIAÇÃO DO CALVÁRIO</option>
                <option value="	ASSOCIACAO DO CLUBE DE MAES CRIANCA ESPERANCA DE SANTO INACIO">ASSOCIACAO DO CLUBE DE MAES CRIANCA ESPERANCA DE SANTO INACIO</option>
                <option value="	ASSOCIACAO DO INSTITUTO ULLY">ASSOCIACAO DO INSTITUTO ULLY</option>
                <option value="	ASSOCIAÇÃO DO MORRO PARA O RINGUE">ASSOCIAÇÃO DO MORRO PARA O RINGUE</option>
                <option value="	ASSOCIAÇÃO DOS AMIGOS NO COMBATE A EXCLUSÃO SOCIAL">ASSOCIAÇÃO DOS AMIGOS NO COMBATE A EXCLUSÃO SOCIAL</option>
                <option value="	ASSOCIAÇÃO DOS CUIDADORES DE PESSOAS EM SITUAÇÃO DE VULNERABILIDADE NA BAHIA">ASSOCIAÇÃO DOS CUIDADORES DE PESSOAS EM SITUAÇÃO DE VULNERABILIDADE NA BAHIA</option>
                <option value="	ASSOCIACAO DOS ESPORTES RADICAIS DE AQUIRAZ">ASSOCIACAO DOS ESPORTES RADICAIS DE AQUIRAZ</option>
                <option value="	ASSOCIAÇÃO DOS MORADORES DA COMUNIDADE DO BAIRRO ZITA CUNHA">ASSOCIAÇÃO DOS MORADORES DA COMUNIDADE DO BAIRRO ZITA CUNHA</option>
                <option value="	ASSOCIACAO DOS MORADORES E AMIGOS DO BAIRRO ITINGA">ASSOCIACAO DOS MORADORES E AMIGOS DO BAIRRO ITINGA</option>
                <option value="	ASSOCIACAO DOS PRODUTORES E AGRICULTORES DE SAO BOA VENTURA">ASSOCIACAO DOS PRODUTORES E AGRICULTORES DE SAO BOA VENTURA</option>
                <option value="	ASSOCIAÇÃO DOS REUMÁTICOS DE UBERLÂNDIA E REGIÃO - ARUR">ASSOCIAÇÃO DOS REUMÁTICOS DE UBERLÂNDIA E REGIÃO - ARUR</option>
                <option value="	ASSOCIACAO EDUCACAO,CULTURA,ARTE E CIDADANIA">ASSOCIACAO EDUCACAO,CULTURA,ARTE E CIDADANIA</option>
                <option value="	ASSOCIAÇÃO EDUCACIONAL CULTURAL E SOCIAL APRENDER">ASSOCIAÇÃO EDUCACIONAL CULTURAL E SOCIAL APRENDER</option>
                <option value="	ASSOCIACAO EDUCACIONAL E ASSISTENCIAL CASA DOS AMARELINHOS">ASSOCIACAO EDUCACIONAL E ASSISTENCIAL CASA DOS AMARELINHOS</option>
                <option value="	ASSOCIAÇÃO EDUCACIONAL E BENEFICENTE PÃO DA VIDA">ASSOCIAÇÃO EDUCACIONAL E BENEFICENTE PÃO DA VIDA</option>
                <option value="	ASSOCIACAO EDUCACIONAL E ESPORTIVA ALEM DAS 4 LINHAS">ASSOCIACAO EDUCACIONAL E ESPORTIVA ALEM DAS 4 LINHAS</option>
                <option value="	ASSOCIAÇÃO EDUCACIONAL ESPORTIVA E SOCIAL VOZ ATIVA">ASSOCIAÇÃO EDUCACIONAL ESPORTIVA E SOCIAL VOZ ATIVA</option>
                <option value="	ASSOCIACAO EDUCAR, TRANSFORMAR E INCLUIR">ASSOCIACAO EDUCAR, TRANSFORMAR E INCLUIR</option>
                <option value="	ASSOCIACAO EDUCATIVA E CULTURAL ARTE GERACAO">ASSOCIACAO EDUCATIVA E CULTURAL ARTE GERACAO</option>
                <option value="	ASSOCIAÇÃO ELAS PODEM RN">ASSOCIAÇÃO ELAS PODEM RN</option>
                <option value="	ASSOCIACAO ELISABETH BRUYERE">ASSOCIACAO ELISABETH BRUYERE</option>
                <option value="	ASSOCIAÇÃO EMAÚS">ASSOCIAÇÃO EMAÚS</option>
                <option value="	ASSOCIAÇÃO ENSINANDO A PESCAR">ASSOCIAÇÃO ENSINANDO A PESCAR</option>
                <option value="	ASSOCIAÇÃO ESPERANÇA E DESTINO">ASSOCIAÇÃO ESPERANÇA E DESTINO</option>
                <option value="	ASSOCIAÇÃO ESPORTE E VIDA">ASSOCIAÇÃO ESPORTE E VIDA</option>
                <option value="	ASSOCIAÇÃO ESPORTIVA CULTURAL E E EDUCACIONAL ABRAÇO CAMPEÃO">ASSOCIAÇÃO ESPORTIVA CULTURAL E E EDUCACIONAL ABRAÇO CAMPEÃO</option>
                <option value="	ASSOCIAÇÃO ESPORTIVA CULTURAL E EDUCACIONAL INSTITUTO IGOR CHATUBINHA">ASSOCIAÇÃO ESPORTIVA CULTURAL E EDUCACIONAL INSTITUTO IGOR CHATUBINHA</option>
                <option value="	ASSOCIACAO ESPORTIVA TRANSFORMACAO">ASSOCIACAO ESPORTIVA TRANSFORMACAO</option>
                <option value="	ASSOCIAÇÃO ESTRELAS DO AMANHÃ">ASSOCIAÇÃO ESTRELAS DO AMANHÃ</option>
                <option value="	ASSOCIAÇÃO ETHOS SUSTENTÁVEL">ASSOCIAÇÃO ETHOS SUSTENTÁVEL</option>
                <option value="	ASSOCIACAO FAMILIA RACA NEGRA JARDIM SAO CARLOS">ASSOCIACAO FAMILIA RACA NEGRA JARDIM SAO CARLOS</option>
                <option value="	ASSOCIACAO FILANTROPICA ARTE SALVA VIDAS">ASSOCIACAO FILANTROPICA ARTE SALVA VIDAS</option>
                <option value="	ASSOCIACAO FORCA ESPERANCA">ASSOCIACAO FORCA ESPERANCA</option>
                <option value="	ASSOCIAÇÃO FUTURO CAMPEÃO">ASSOCIAÇÃO FUTURO CAMPEÃO</option>
                <option value="	ASSOCIACAO GIBI ESPORTE E EDUCACAO">ASSOCIACAO GIBI ESPORTE E EDUCACAO</option>
                <option value="	ASSOCIAÇÃO GRUPO AÇÃO SOCIAL ATENDER SEMPRE">ASSOCIAÇÃO GRUPO AÇÃO SOCIAL ATENDER SEMPRE</option>
                <option value="	ASSOCIAÇÃO GRUPO DE ASSISTÊNCIA INTEGRAL À INFÂNCIA E ADOLESCÊNCIA">ASSOCIAÇÃO GRUPO DE ASSISTÊNCIA INTEGRAL À INFÂNCIA E ADOLESCÊNCIA</option>
                <option value="	ASSOCIAÇÃO GRUPO DE MÃES NOVO AMANHECER">ASSOCIAÇÃO GRUPO DE MÃES NOVO AMANHECER</option>
                <option value="	ASSOCIACAO HUMANITARIA PEQUENINOS DO MONTE">ASSOCIACAO HUMANITARIA PEQUENINOS DO MONTE</option>
                <option value="	ASSOCIAÇAO INFANCIA E FAMILIA">ASSOCIAÇAO INFANCIA E FAMILIA</option>
                <option value="	ASSOCIACAO INICIATIVA CULTURAL">ASSOCIACAO INICIATIVA CULTURAL</option>
                <option value="	ASSOCIACAO INSTITUTO CULTURA QUEREMOS FAZER">ASSOCIACAO INSTITUTO CULTURA QUEREMOS FAZER</option>
                <option value="	ASSOCIAÇÃO INSTITUTO DE MULHERES NEGRAS HERDEIRAS DE CANDACES">ASSOCIAÇÃO INSTITUTO DE MULHERES NEGRAS HERDEIRAS DE CANDACES</option>
                <option value="	ASSOCIACAO INSTITUTO EFEITO URBANO">ASSOCIACAO INSTITUTO EFEITO URBANO</option>
                <option value="	ASSOCIAÇÃO INSTITUTO MORIÁ">ASSOCIAÇÃO INSTITUTO MORIÁ</option>
                <option value="	ASSOCIACAO INSTITUTO TRANSFORMAR">ASSOCIACAO INSTITUTO TRANSFORMAR</option>
                <option value="	ASSOCIACAO INTEGRADA DE CULTURA/ ESPORTE E LAZER">ASSOCIACAO INTEGRADA DE CULTURA/ ESPORTE E LAZER</option>
                <option value="	ASSOCIAÇÃO INTEGRANDO E CONSTRUINDO O CONHECIMENTO-AICC">ASSOCIAÇÃO INTEGRANDO E CONSTRUINDO O CONHECIMENTO-AICC</option>
                <option value="	ASSOCIAÇÃO INTERNACIONAL DE CAPOEIRA OS BAMBAS DO SOL NASCENTE DE SALVADOR">ASSOCIAÇÃO INTERNACIONAL DE CAPOEIRA OS BAMBAS DO SOL NASCENTE DE SALVADOR</option>
                <option value="	ASSOCIAÇÃO ITAMAR PARA PROMOÇÃO DE ESPORTES E CULTURA NA FAVELA">ASSOCIAÇÃO ITAMAR PARA PROMOÇÃO DE ESPORTES E CULTURA NA FAVELA</option>
                <option value="	ASSOCIACAO IYD BRASIL">ASSOCIACAO IYD BRASIL</option>
                <option value="	ASSOCIAÇÃO JOGANDO PELO REINO">ASSOCIAÇÃO JOGANDO PELO REINO</option>
                <option value="	ASSOCIAÇÃO LACRE DO BEM">ASSOCIAÇÃO LACRE DO BEM</option>
                <option value="	ASSOCIAÇÃO LER E SABER NA COMUNIDADE">ASSOCIAÇÃO LER E SABER NA COMUNIDADE</option>
                <option value="	ASSOCIAÇÃO LIVRE  - CENTRO DE FORMAÇÃO E TRANSFORMAÇÃO SOCIAL (TRANSFORMAR)">ASSOCIAÇÃO LIVRE  - CENTRO DE FORMAÇÃO E TRANSFORMAÇÃO SOCIAL (TRANSFORMAR)</option>
                <option value="	ASSOCIAÇÃO LYRA">ASSOCIAÇÃO LYRA</option>
                <option value="	ASSOCIAÇÃO MÃES QUE INFORMAM">ASSOCIAÇÃO MÃES QUE INFORMAM</option>
                <option value="	ASSOCIAÇÃO MAIS HUMANOS">ASSOCIAÇÃO MAIS HUMANOS</option>
                <option value="	ASSOCIACAO MANA - ACOES QUE TRANSFORMAM VIDAS">ASSOCIACAO MANA - ACOES QUE TRANSFORMAM VIDAS</option>
                <option value="	ASSOCIAÇÃO MÃO NO ARADO">ASSOCIAÇÃO MÃO NO ARADO</option>
                <option value="	ASSOCIACAO MENINAS E MULHERES DO MORRO">ASSOCIACAO MENINAS E MULHERES DO MORRO</option>
                <option value="	ASSOCIAÇÃO MENINOS DA ARACY">ASSOCIAÇÃO MENINOS DA ARACY</option>
                <option value="	ASSOCIAÇÃO MISSÃO EM AÇÃO SOCIAL - EMEAS">ASSOCIAÇÃO MISSÃO EM AÇÃO SOCIAL - EMEAS</option>
                <option value="	ASSOCIAÇÃO MISSÃO INTENSIDADE">ASSOCIAÇÃO MISSÃO INTENSIDADE</option>
                <option value="	ASSOCIAÇÃO MÚSICA AMIGOS SOLIDÁRIOS AMAS">ASSOCIAÇÃO MÚSICA AMIGOS SOLIDÁRIOS AMAS</option>
                <option value="	ASSOCIAÇÃO MÚSICA DE BAIRRO">ASSOCIAÇÃO MÚSICA DE BAIRRO</option>
                <option value="	ASSOCIAÇÃO NOSSA CASA MÃE ÁFRICA ESTUDOS E COMUNICAÇÃO">ASSOCIAÇÃO NOSSA CASA MÃE ÁFRICA ESTUDOS E COMUNICAÇÃO</option>
                <option value="	ASSOCIAÇÃO NÚCLEO DE EDUCAÇÃO COMUNITÁRIA DO COROADINHO">ASSOCIAÇÃO NÚCLEO DE EDUCAÇÃO COMUNITÁRIA DO COROADINHO</option>
                <option value="	ASSOCIACAO O APRISCO">ASSOCIACAO O APRISCO</option>
                <option value="	ASSOCIACAO OFICINA DO ESPORTE DE PIRAUBA">ASSOCIACAO OFICINA DO ESPORTE DE PIRAUBA</option>
                <option value="	ASSOCIAÇÃO OLHAR DO FUTURO">ASSOCIAÇÃO OLHAR DO FUTURO</option>
                <option value="	ASSOCIAÇÃO ONG DO RISO">ASSOCIAÇÃO ONG DO RISO</option>
                <option value="	ASSOCIAÇÃO PADRE PAULO TONUCCI">ASSOCIAÇÃO PADRE PAULO TONUCCI</option>
                <option value="	ASSOCIAÇÃO PARCEIROS DA ALEGRIA">ASSOCIAÇÃO PARCEIROS DA ALEGRIA</option>
                <option value="	ASSOCIACAO PECEM EU TE AMO">ASSOCIACAO PECEM EU TE AMO</option>
                <option value="	ASSOCIACAO PERY FERIA">ASSOCIACAO PERY FERIA</option>
                <option value="	ASSOCIACAO PESCA MARAVILHOSA">ASSOCIACAO PESCA MARAVILHOSA</option>
                <option value="	ASSOCIACAO PESTALOZZI DE PARINTINS">ASSOCIACAO PESTALOZZI DE PARINTINS</option>
                <option value="	ASSOCIACAO PINTANDO O SETEAZUL - APSA/CE">ASSOCIACAO PINTANDO O SETEAZUL - APSA/CE</option>
                <option value="	ASSOCIAÇÃO PLENITUDE DO AMOR (APA)">ASSOCIAÇÃO PLENITUDE DO AMOR (APA)</option>
                <option value="	ASSOCIAÇÃO PRO CULTURA- MONTE SANTO DE MINAS">ASSOCIAÇÃO PRO CULTURA- MONTE SANTO DE MINAS</option>
                <option value="	ASSOCIACAO PROJETO EMUNAH">ASSOCIACAO PROJETO EMUNAH</option>
                <option value="	ASSOCIAÇÃO PROJETO ESPERANÇA DO BRASIL">ASSOCIAÇÃO PROJETO ESPERANÇA DO BRASIL</option>
                <option value="	ASSOCIAÇAO PROJETO GADITAS">ASSOCIAÇAO PROJETO GADITAS</option>
                <option value="	ASSOCIACAO PROJETO IRMAO SOL IRMA LUA">ASSOCIACAO PROJETO IRMAO SOL IRMA LUA</option>
                <option value="	ASSOCIAÇÃO PROJETO MALTRAPILHO">ASSOCIAÇÃO PROJETO MALTRAPILHO</option>
                <option value="	ASSOCIACAO PROJETO NOVA ESPERANCA TAPANA">ASSOCIACAO PROJETO NOVA ESPERANCA TAPANA</option>
                <option value="	ASSOCIAÇÃO PROJETO PONTO E VÍRGULA">ASSOCIAÇÃO PROJETO PONTO E VÍRGULA</option>
                <option value="	ASSOCIAÇÃO PROJETO PRÓ BEM">ASSOCIAÇÃO PROJETO PRÓ BEM</option>
                <option value="	ASSOCIACAO PROJETO PROCEDER">ASSOCIACAO PROJETO PROCEDER</option>
                <option value="	ASSOCIACAO ''PROJETO RELFE''">ASSOCIACAO ''PROJETO RELFE''</option>
                <option value="	ASSOCIAÇÃO PROJETO SURFAR">ASSOCIAÇÃO PROJETO SURFAR</option>
                <option value="	ASSOCIACAO PROT ASSIST MATERN INFANCIA LAGOA REDONDA">ASSOCIACAO PROT ASSIST MATERN INFANCIA LAGOA REDONDA</option>
                <option value="	ASSOCIAÇÃO PROTOTIPANDO A QUEBRADA">ASSOCIAÇÃO PROTOTIPANDO A QUEBRADA</option>
                <option value="	ASSOCIAÇÃO PROVER">ASSOCIAÇÃO PROVER</option>
                <option value="	ASSOCIAÇÃO RAÍZES DE GERICINÓ">ASSOCIAÇÃO RAÍZES DE GERICINÓ</option>
                <option value="	ASSOCIACAO RECANTO SOCIAL BOM PASTOR">ASSOCIACAO RECANTO SOCIAL BOM PASTOR</option>
                <option value="	ASSOCIACAO RECREATIVA CULTURAL E EDUCACIONAL MALCOLM X">ASSOCIACAO RECREATIVA CULTURAL E EDUCACIONAL MALCOLM X</option>
                <option value="	ASSOCIAÇÃO REMEDIAR">ASSOCIAÇÃO REMEDIAR</option>
                <option value="	ASSOCIACAO RENASCER CONSTRUINDO O FUTURO - ARCF">ASSOCIACAO RENASCER CONSTRUINDO O FUTURO - ARCF</option>
                <option value="	ASSOCIACAO RESILIENCIA">ASSOCIACAO RESILIENCIA</option>
                <option value="	ASSOCIAÇÃO SANTO DIAS">ASSOCIAÇÃO SANTO DIAS</option>
                <option value="	ASSOCIAÇÃO SÃO VICENTE DE PAULO DO JD VITÓRIA">ASSOCIAÇÃO SÃO VICENTE DE PAULO DO JD VITÓRIA</option>
                <option value="	ASSOCIAÇÃO SEMEANDO AMOR">ASSOCIAÇÃO SEMEANDO AMOR</option>
                <option value="	ASSOCIAÇÃO SEMENTES DO VALE">ASSOCIAÇÃO SEMENTES DO VALE</option>
                <option value="	ASSOCIAÇÃO SER CIDADÃO">ASSOCIAÇÃO SER CIDADÃO</option>
                <option value="	ASSOCIACAO SERGIPANA DE DESENVOLVIMENTO COMUNITARIO E RESGATE DA CIDADANIA - ASDECRAC">ASSOCIACAO SERGIPANA DE DESENVOLVIMENTO COMUNITARIO E RESGATE DA CIDADANIA - ASDECRAC</option>
                <option value="	ASSOCIACAO SERGIPANA DE TRANSGENEROS">ASSOCIACAO SERGIPANA DE TRANSGENEROS</option>
                <option value="	ASSOCIAÇÃO SERVOS">ASSOCIAÇÃO SERVOS</option>
                <option value="	ASSOCIAÇÃO SHOTO KARATÊ DO KAI">ASSOCIAÇÃO SHOTO KARATÊ DO KAI</option>
                <option value="	ASSOCIAÇÃO SOCIAL BLACK.S URBANO">ASSOCIAÇÃO SOCIAL BLACK.S URBANO</option>
                <option value="	ASSOCIAÇÃO SOCIAL CULTURAL E ESPORTIVA EDUCAPOEIRA">ASSOCIAÇÃO SOCIAL CULTURAL E ESPORTIVA EDUCAPOEIRA</option>
                <option value="	ASSOCIACAO SOCIAL EM ACAO">ASSOCIACAO SOCIAL EM ACAO</option>
                <option value="	ASSOCIAÇÃO SOCIAL PAULO VI">ASSOCIAÇÃO SOCIAL PAULO VI</option>
                <option value="	ASSOCIAÇÃO SOCIAL SAGRADA FAMÍLIA">ASSOCIAÇÃO SOCIAL SAGRADA FAMÍLIA</option>
                <option value="	ASSOCIAÇÃO SOCIEDADE BENEFICENTE O BOM SAMARITANO DE CACHOEIRINHA">ASSOCIAÇÃO SOCIEDADE BENEFICENTE O BOM SAMARITANO DE CACHOEIRINHA</option>
                <option value="	ASSOCIACAO SOCIEDADE EM ACAO">ASSOCIACAO SOCIEDADE EM ACAO</option>
                <option value="	ASSOCIAÇÃO SOCIOCULTURAL DO BOM JARDIM">ASSOCIAÇÃO SOCIOCULTURAL DO BOM JARDIM</option>
                <option value="	ASSOCIACAO SOLIDARIA DE DESENVOLVIMENTO HUMANO - ASDH">ASSOCIACAO SOLIDARIA DE DESENVOLVIMENTO HUMANO - ASDH</option>
                <option value="	ASSOCIACAO SONS DO BEM">ASSOCIACAO SONS DO BEM</option>
                <option value="	ASSOCIAÇÃO SWELL SURF BAÍA FORMOSA">ASSOCIAÇÃO SWELL SURF BAÍA FORMOSA</option>
                <option value="	ASSOCIAÇÃO TINGUI">ASSOCIAÇÃO TINGUI</option>
                <option value="	ASSOCIACAO TOCANDO EM FRENTE">ASSOCIACAO TOCANDO EM FRENTE</option>
                <option value="	ASSOCIAÇÃO TONKIRI VOA">ASSOCIAÇÃO TONKIRI VOA</option>
                <option value="	ASSOCIAÇÃO UM CHUTE PARA O FUTURO">ASSOCIAÇÃO UM CHUTE PARA O FUTURO</option>
                <option value="	ASSOCIAÇÃO UNAIENSE DE DESENVOLVIMENTO E CIDADANIA">ASSOCIAÇÃO UNAIENSE DE DESENVOLVIMENTO E CIDADANIA</option>
                <option value="	ASSOCIAÇÃO UNIAÇÃO UNIÃO DE AMIGOS EM AÇÃO">ASSOCIAÇÃO UNIAÇÃO UNIÃO DE AMIGOS EM AÇÃO</option>
                <option value="	ASSOCIAÇÃO UNIDA PELA FÉ FORÇA E ESPERANÇA">ASSOCIAÇÃO UNIDA PELA FÉ FORÇA E ESPERANÇA</option>
                <option value="	ASSOCIAÇÃO VIDANÇA COMPANHIA DE DANÇAS DO CEARÁ">ASSOCIAÇÃO VIDANÇA COMPANHIA DE DANÇAS DO CEARÁ</option>
                <option value="	ASSOCIACAO VIDIGAL CULTURAL">ASSOCIACAO VIDIGAL CULTURAL</option>
                <option value="	ASSOCIAÇÃO VIVENDO A ARTE">ASSOCIAÇÃO VIVENDO A ARTE</option>
                <option value="	ASSOCIACAO VIVER EM FAMILIA PARA UM FUTURO MELHOR">ASSOCIACAO VIVER EM FAMILIA PARA UM FUTURO MELHOR</option>
                <option value="	ASSOCIAÇÃO VOAR">ASSOCIAÇÃO VOAR</option>
                <option value="	ASSOCIAÇÃO ZEDAKAH DE JUSTIÇA SOCIAL">ASSOCIAÇÃO ZEDAKAH DE JUSTIÇA SOCIAL</option>
                <option value="	ASSOCIAÇÃO ZEIZA DOJO">ASSOCIAÇÃO ZEIZA DOJO</option>
                <option value="	ASSOCIACAO ZUMBI DE CAPOEIRA - AZC">ASSOCIACAO ZUMBI DE CAPOEIRA - AZC</option>
                <option value="	ASSOCIÇÃO COMUNITARIA CRISTA EBENZER">ASSOCIÇÃO COMUNITARIA CRISTA EBENZER</option>
                <option value="	AVGG - ASSOCIAÇÃO DE VOLUNTÁRIOS GRUPO GOIANIANIA">AVGG - ASSOCIAÇÃO DE VOLUNTÁRIOS GRUPO GOIANIANIA</option>
                <option value="	BAIRRO DA JUVENTUDE DOS PADRES ROGACIONISTAS">BAIRRO DA JUVENTUDE DOS PADRES ROGACIONISTAS</option>
                <option value="	BALÉ DA RALE">BALÉ DA RALE</option>
                <option value="	BARBEARIA SOLIDÁRIA">BARBEARIA SOLIDÁRIA</option>
                <option value="	BATUCARTE">BATUCARTE</option>
                <option value="	BERIMBAU HOSTEL E COZINHA ARTESANAL">BERIMBAU HOSTEL E COZINHA ARTESANAL</option>
                <option value="	BIBLIOTECA COMUNITÁRIA ESPAÇO LITERÁRIO LIVRO LIVRE">BIBLIOTECA COMUNITÁRIA ESPAÇO LITERÁRIO LIVRO LIVRE</option>
                <option value="	BIBLIOTECA COMUNITÁRIA ROEDORES DE LIVROS">BIBLIOTECA COMUNITÁRIA ROEDORES DE LIVROS</option>
                <option value="	BIBLIOTECA ITINERANTE COMUNITÁRIA BARCA LITERÁRIA">BIBLIOTECA ITINERANTE COMUNITÁRIA BARCA LITERÁRIA</option>
                <option value="	BOA SAFRA TEAM">BOA SAFRA TEAM</option>
                <option value="	BRASIL EM AÇÃO">BRASIL EM AÇÃO</option>
                <option value="	BRIGADA BRASIL POSTO DA MATA">BRIGADA BRASIL POSTO DA MATA</option>
                <option value="	BRINCAR E APRENDER">BRINCAR E APRENDER</option>
                <option value="	BRINQUEDOTECA DA TIA ANA">BRINQUEDOTECA DA TIA ANA</option>
                <option value="	BROTHERS DO BEM">BROTHERS DO BEM</option>
                <option value="	BUTIJA SOCIAL ESPORTE E CULTURA">BUTIJA SOCIAL ESPORTE E CULTURA</option>
                <option value="	C.A.E.F.A - CENTRO DE APOIO A EDUCAÇÃO E FORMAÇÃO DO ADOLESCENTE">C.A.E.F.A - CENTRO DE APOIO A EDUCAÇÃO E FORMAÇÃO DO ADOLESCENTE</option>
                <option value="	CAÇADORES">CAÇADORES</option>
                <option value="	CAISCA - CENTRO DE ARTES E INTEGRAÇÃO SOCIAL PARA CRIANÇAS E ADOLESCENTE">CAISCA - CENTRO DE ARTES E INTEGRAÇÃO SOCIAL PARA CRIANÇAS E ADOLESCENTE</option>
                <option value="	CAMA-CENTRO DE ARTE E MEIO AMBIENTE">CAMA-CENTRO DE ARTE E MEIO AMBIENTE</option>
                <option value="	CANTINHO DA BELINHA">CANTINHO DA BELINHA</option>
                <option value="	CARFER TREINAMENTOS E DESENVOLVIMENTO HUMANO">CARFER TREINAMENTOS E DESENVOLVIMENTO HUMANO</option>
                <option value="	CARITAS DIOCESANA DA DIOCESE DE MIRACEMA DO TOCANTINS">CARITAS DIOCESANA DA DIOCESE DE MIRACEMA DO TOCANTINS</option>
                <option value="	CARLOS AUGUSTO GOUVEA FERREIRA 01129215237">CARLOS AUGUSTO GOUVEA FERREIRA 01129215237</option>
                <option value="	CASA ASSISTENCIAL PAI JOAQUIM DE ANGOLA">CASA ASSISTENCIAL PAI JOAQUIM DE ANGOLA</option>
                <option value="	CASA DA COMUNIDADE DO BERARDO - CCB SOCIAL">CASA DA COMUNIDADE DO BERARDO - CCB SOCIAL</option>
                <option value="	CASA DA COMUNIDADE UNIDOS POR CRISTO">CASA DA COMUNIDADE UNIDOS POR CRISTO</option>
                <option value="	CASA DA REVOLUÇÃO/PROJETO ESMERALDA">CASA DA REVOLUÇÃO/PROJETO ESMERALDA</option>
                <option value="	CASA DAS JUVENTUDES / ASSOCIAÇÃO SANTO AGOSTINHO">CASA DAS JUVENTUDES / ASSOCIAÇÃO SANTO AGOSTINHO</option>
                <option value="	CASA DE APOIO À CRIANÇA COM CÂNCER VIDA DIVINA">CASA DE APOIO À CRIANÇA COM CÂNCER VIDA DIVINA</option>
                <option value="	CASA DE CULTURA AFRO DESCENDENTES FILHOS DE MARIA">CASA DE CULTURA AFRO DESCENDENTES FILHOS DE MARIA</option>
                <option value="	CASA DO CONTO">CASA DO CONTO</option>
                <option value="	CASA JOVEM APRENDIZ">CASA JOVEM APRENDIZ</option>
                <option value="	CASA SÃO FRANCISCO">CASA SÃO FRANCISCO</option>
                <option value="	CASULO">CASULO</option>
                <option value="	CCM - CENTRO CULTURAL MAMULENGO">CCM - CENTRO CULTURAL MAMULENGO</option>
                <option value="	CCVH">CCVH</option>
                <option value="	CECRE CENTRO EDUCACIONAL COMUNITÁRIO REDENÇÃO">CECRE CENTRO EDUCACIONAL COMUNITÁRIO REDENÇÃO</option>
                <option value="	CENTRAL DA SOLIDARIEDADE">CENTRAL DA SOLIDARIEDADE</option>
                <option value="	CENTRAL DE APOIO DOS AMIGOS SOLIDARIOS">CENTRAL DE APOIO DOS AMIGOS SOLIDARIOS</option>
                <option value="	CENTRO  DOS JOVENS  UNIDOS  NA  MILITANCIA  COMUNITARIA  DE PAULISTA">CENTRO  DOS JOVENS  UNIDOS  NA  MILITANCIA  COMUNITARIA  DE PAULISTA</option>
                <option value="	CENTRO ASSISTENCIAL ATITUDES SOLIDARIAS">CENTRO ASSISTENCIAL ATITUDES SOLIDARIAS</option>
                <option value="	CENTRO BENEFICENTE NOSSA SENHORA DA GLÓRIA">CENTRO BENEFICENTE NOSSA SENHORA DA GLÓRIA</option>
                <option value="	CENTRO CIDADANIA AÇÃO E EDUCAÇÃO SOCIOAMBIENTAL">CENTRO CIDADANIA AÇÃO E EDUCAÇÃO SOCIOAMBIENTAL</option>
                <option value="	CENTRO COMUNITÁRIO DA PAZ">CENTRO COMUNITÁRIO DA PAZ</option>
                <option value="	CENTRO COMUNITARIO DA VILA PALMEIRA">CENTRO COMUNITARIO DA VILA PALMEIRA</option>
                <option value="	CENTRO COMUNITARIO DE AUDIOVISUAL LUIZ ORLANDO">CENTRO COMUNITARIO DE AUDIOVISUAL LUIZ ORLANDO</option>
                <option value="	CENTRO COMUNITÁRIO DO RADIONAL E ADJACÊNCIAS">CENTRO COMUNITÁRIO DO RADIONAL E ADJACÊNCIAS</option>
                <option value="	CENTRO COMUNITÁRIO DO ROSARINHO">CENTRO COMUNITÁRIO DO ROSARINHO</option>
                <option value="	CENTRO COMUNITÁRIO MANOEL VITORINO">CENTRO COMUNITÁRIO MANOEL VITORINO</option>
                <option value="	CENTRO COMUNITÁRIO MÁRIO ANDRADE">CENTRO COMUNITÁRIO MÁRIO ANDRADE</option>
                <option value="	CENTRO COMUNITARIO UNIDOS PELO SOCIAL">CENTRO COMUNITARIO UNIDOS PELO SOCIAL</option>
                <option value="	CENTRO COMUNTÁRIO IRMÃOS KENNEDY">CENTRO COMUNTÁRIO IRMÃOS KENNEDY</option>
                <option value="	CENTRO CULTURAL DONA ANTÔNIA">CENTRO CULTURAL DONA ANTÔNIA</option>
                <option value="	CENTRO CULTURAL NACAO MESTICA">CENTRO CULTURAL NACAO MESTICA</option>
                <option value="	CENTRO CULTURAL RITA DE CASSIA">CENTRO CULTURAL RITA DE CASSIA</option>
                <option value="	CENTRO DE ACAO COMUNITARIA DO CEARA - CACC">CENTRO DE ACAO COMUNITARIA DO CEARA - CACC</option>
                <option value="	CENTRO DE AÇÃO SOCIAL/ EDUCAÇÃO PROFISSIONAL E ESPORTIVA GARIMPANDO DIAMANTES">CENTRO DE AÇÃO SOCIAL/ EDUCAÇÃO PROFISSIONAL E ESPORTIVA GARIMPANDO DIAMANTES</option>
                <option value="	CENTRO DE APOIO PEDAGÓGICO EDUCAÇÃO">CENTRO DE APOIO PEDAGÓGICO EDUCAÇÃO</option>
                <option value="	CENTRO DE ASSISTÊNCIA SOCIAL E CULTURAL UNIÃO DA ILHA">CENTRO DE ASSISTÊNCIA SOCIAL E CULTURAL UNIÃO DA ILHA</option>
                <option value="	CENTRO DE CIDADANIA VIDA NOVA BELFORD ROXO PIAM">CENTRO DE CIDADANIA VIDA NOVA BELFORD ROXO PIAM</option>
                <option value="	CENTRO DE DESENVOLVIMENTO COMUNITÁRIO VILA LEONINA">CENTRO DE DESENVOLVIMENTO COMUNITÁRIO VILA LEONINA</option>
                <option value="	CENTRO DE EDUCAÇÃO INTEGRADA CRAQUES DA VIDA">CENTRO DE EDUCAÇÃO INTEGRADA CRAQUES DA VIDA</option>
                <option value="	CENTRO DE INTEGRACAO SOCIAL AMIGOS DE NOVA ERA">CENTRO DE INTEGRACAO SOCIAL AMIGOS DE NOVA ERA</option>
                <option value="	CENTRO DE INTEGRAÇÃO SOCIAL E CULTURAL - CISC UMA CHANCE">CENTRO DE INTEGRAÇÃO SOCIAL E CULTURAL - CISC UMA CHANCE</option>
                <option value="	CENTRO DE RECREAÇÃO INFANTIL AMAR MAIS MUITO MAIS">CENTRO DE RECREAÇÃO INFANTIL AMAR MAIS MUITO MAIS</option>
                <option value="	CENTRO DE RECUPERAÇÃO E EDUCAÇÃO NUTRICIONAL">CENTRO DE RECUPERAÇÃO E EDUCAÇÃO NUTRICIONAL</option>
                <option value="	CENTRO EDUCACIONAL COMUNITARIO ADONAY CRUZEIRO DE SANTA BARBARA">CENTRO EDUCACIONAL COMUNITARIO ADONAY CRUZEIRO DE SANTA BARBARA</option>
                <option value="	CENTRO EDUCACIONAL DE ESPORTE E LAZER COMUNIDADES UNIDAS">CENTRO EDUCACIONAL DE ESPORTE E LAZER COMUNIDADES UNIDAS</option>
                <option value="	CENTRO EDUCACIONAL POPULAR SABER VIVER">CENTRO EDUCACIONAL POPULAR SABER VIVER</option>
                <option value="	CENTRO EDUCACIONAL/ CULTURAL E SOCIAL DO IBURA">CENTRO EDUCACIONAL/ CULTURAL E SOCIAL DO IBURA</option>
                <option value="	CENTRO ESCOLA MANGUE">CENTRO ESCOLA MANGUE</option>
                <option value="	CENTRO INTEGRADO DE ACOES COMUNITARIAS PELA VIDA">CENTRO INTEGRADO DE ACOES COMUNITARIAS PELA VIDA</option>
                <option value="	CENTRO RECREAÇÃO DE ATENDIEMNTO E DEFESA DA CRIANÇA E ADOLESECENTE">CENTRO RECREAÇÃO DE ATENDIEMNTO E DEFESA DA CRIANÇA E ADOLESECENTE</option>
                <option value="	CENTRO SOCIAL ACOLHER">CENTRO SOCIAL ACOLHER</option>
                <option value="	CENTRO SOCIAL CAMINHO SEGURO">CENTRO SOCIAL CAMINHO SEGURO</option>
                <option value="	CENTRO SOCIAL COMUNITÁRIO FAVELA EM DESENVOLVIMENTO">CENTRO SOCIAL COMUNITÁRIO FAVELA EM DESENVOLVIMENTO</option>
                <option value="	CENTRO SOCIAL DÍNAMUS">CENTRO SOCIAL DÍNAMUS</option>
                <option value="	CENTRO SOCIAL NOSSA SENHORA DO BOM PARTO">CENTRO SOCIAL NOSSA SENHORA DO BOM PARTO</option>
                <option value="	CENTRO UNIAO BENEF DOS M DO BAIRRO DA GRANJA PORTUGAL">CENTRO UNIAO BENEF DOS M DO BAIRRO DA GRANJA PORTUGAL</option>
                <option value="	CHAMA VIVA MISSÕES URBANAS">CHAMA VIVA MISSÕES URBANAS</option>
                <option value="	CHEF ANGELO MAGNO">CHEF ANGELO MAGNO</option>
                <option value="	CHEGANÇA ESCOLA">CHEGANÇA ESCOLA</option>
                <option value="	CIA BALLET GRANDK">CIA BALLET GRANDK</option>
                <option value="	CIDADE FELIZ">CIDADE FELIZ</option>
                <option value="	CIDADELA CENTRO CULTURAL E AMBIENTAL">CIDADELA CENTRO CULTURAL E AMBIENTAL</option>
                <option value="	CINECLUBE TIA NILDA">CINECLUBE TIA NILDA</option>
                <option value="	CLUBE DE AJUDA">CLUBE DE AJUDA</option>
                <option value="	CLUBE DE MAES MARIA DE FATIMA CALDAS">CLUBE DE MAES MARIA DE FATIMA CALDAS</option>
                <option value="	CLUBE DO BEM">CLUBE DO BEM</option>
                <option value="	CLUBE DONAS">CLUBE DONAS</option>
                <option value="	COCOMOD CONSELHO COMUNITARIO OITO DE DEZEMBRO">COCOMOD CONSELHO COMUNITARIO OITO DE DEZEMBRO</option>
                <option value="	COLAÍ MOVIMENTO DE CULTURA">COLAÍ MOVIMENTO DE CULTURA</option>
                <option value="	COLETIVO ANDANÇAS DF">COLETIVO ANDANÇAS DF</option>
                <option value="	COLETIVO ARAUTOS DA POESIA">COLETIVO ARAUTOS DA POESIA</option>
                <option value="	COLETIVO AUTÔNOMO MORRO DA CRUZ">COLETIVO AUTÔNOMO MORRO DA CRUZ</option>
                <option value="	COLETIVO AYOKÁ">COLETIVO AYOKÁ</option>
                <option value="	COLETIVO CARANGUEJO TABAIARES RESISTE">COLETIVO CARANGUEJO TABAIARES RESISTE</option>
                <option value="	COLETIVO CULTURA BETHANIA">COLETIVO CULTURA BETHANIA</option>
                <option value="	COLETIVO CULTURAL E SOCIAL SOL NASCENTE">COLETIVO CULTURAL E SOCIAL SOL NASCENTE</option>
                <option value="	COLETIVO FILHOS NOS BRAÇOS DO PAI">COLETIVO FILHOS NOS BRAÇOS DO PAI</option>
                <option value="	COLETIVO FUNDO DA CAIXA">COLETIVO FUNDO DA CAIXA</option>
                <option value="	COLETIVO MULHERES DO BRASIL EM AÇÃO CMBA">COLETIVO MULHERES DO BRASIL EM AÇÃO CMBA</option>
                <option value="	COLETIVO MULHERES NEGRAS DA PERIFERIA">COLETIVO MULHERES NEGRAS DA PERIFERIA</option>
                <option value="	COLETIVO OLHANDO PRA FRENTE">COLETIVO OLHANDO PRA FRENTE</option>
                <option value="	COLETIVO SARGENTO PERIFA">COLETIVO SARGENTO PERIFA</option>
                <option value="	COLETIVO VIDA NOVA">COLETIVO VIDA NOVA</option>
                <option value="	COLETIVOS EM MOVIMENTO">COLETIVOS EM MOVIMENTO</option>
                <option value="	COLMEIA MARICA">COLMEIA MARICA</option>
                <option value="	COMPARTILHANDO LEITURA">COMPARTILHANDO LEITURA</option>
                <option value="	COMPLEXO ENCANTO GRUPO DE APOIO SOCIAL - CEGAS">COMPLEXO ENCANTO GRUPO DE APOIO SOCIAL - CEGAS</option>
                <option value="	COMUNIDADE ESPERANCA">COMUNIDADE ESPERANCA</option>
                <option value="	COMUNIDADE PORTA DA ESPERANÇA DO BAIRRO PADRE ANDRADE">COMUNIDADE PORTA DA ESPERANÇA DO BAIRRO PADRE ANDRADE</option>
                <option value="	COMUNIDADE PRO MORADIA">COMUNIDADE PRO MORADIA</option>
                <option value="	COMUNIDADE TERAPEUTICA DO MARANHAO">COMUNIDADE TERAPEUTICA DO MARANHAO</option>
                <option value="	COMUNIDADE TERAPEUTICA ZION">COMUNIDADE TERAPEUTICA ZION</option>
                <option value="	COMUNIDADEATIVADOARCO">COMUNIDADEATIVADOARCO</option>
                <option value="	CONEXÃO DO BEM">CONEXÃO DO BEM</option>
                <option value="	CONJER - CONSELHO COMUNITÁRIO DO ALTO JERUSALÉM">CONJER - CONSELHO COMUNITÁRIO DO ALTO JERUSALÉM</option>
                <option value="	CONSELHO COMUNITÁRIO DE AÇÕES SOCIAIS DO CONJUNTO VILA VELHA">CONSELHO COMUNITÁRIO DE AÇÕES SOCIAIS DO CONJUNTO VILA VELHA</option>
                <option value="	CONSELHO COMUNITARIO DE ACOES SOCIAIS DO CONJUNTO VILA VELHA II">CONSELHO COMUNITARIO DE ACOES SOCIAIS DO CONJUNTO VILA VELHA II</option>
                <option value="	CONSELHO DE APOIO AS ASSOCIAÇOES DE UBERABA">CONSELHO DE APOIO AS ASSOCIAÇOES DE UBERABA</option>
                <option value="	CORPORACAO ALIANCA">CORPORACAO ALIANCA</option>
                <option value="	CORREDESSA">CORREDESSA</option>
                <option value="	CORRENTE DO BEM SE">CORRENTE DO BEM SE</option>
                <option value="	CORRENTE DO BEM URUCANIA">CORRENTE DO BEM URUCANIA</option>
                <option value="	CORRENTE DO BEMFSA">CORRENTE DO BEMFSA</option>
                <option value="	CRECHE ESCOLA MARIA DE NAZARÉ">CRECHE ESCOLA MARIA DE NAZARÉ</option>
                <option value="	CREPUSCULO - CENTRO DE DESENVOLVIMENTO HUMANO">CREPUSCULO - CENTRO DE DESENVOLVIMENTO HUMANO</option>
                <option value="	CRIEATIVIDADE GESTÃO ESPORTIVA EMPRESARIAL LTDA">CRIEATIVIDADE GESTÃO ESPORTIVA EMPRESARIAL LTDA</option>
                <option value="	CRISTIAN MORETTI BAZAN 32185760831">CRISTIAN MORETTI BAZAN 32185760831</option>
                <option value="	CUIDAR">CUIDAR</option>
                <option value="	CYGANU' S MOTO CLUBE INSTITUTO EDUCACIONAL E PROFISSIONALIZANTE">CYGANU' S MOTO CLUBE INSTITUTO EDUCACIONAL E PROFISSIONALIZANTE</option>
                <option value="	DAKOLE">DAKOLE</option>
                <option value="	DÉBORA ANTUNES">DÉBORA ANTUNES</option>
                <option value="	DEFICIENTE EFICIENTE">DEFICIENTE EFICIENTE</option>
                <option value="	DELÍ­CIAS CASEIRAS">DELÍ­CIAS CASEIRAS</option>
                <option value="	DESPERTANDO AMOR HOJE">DESPERTANDO AMOR HOJE</option>
                <option value="	DIA DO AMOR">DIA DO AMOR</option>
                <option value="	DOA CG">DOA CG</option>
                <option value="	DOA??AÇÃO">DOA??AÇÃO</option>
                <option value="	EDUCAÇÃO PARA A LIBERDADE">EDUCAÇÃO PARA A LIBERDADE</option>
                <option value="	ELAINE PINHEIRO SANTOS 05564527399">ELAINE PINHEIRO SANTOS 05564527399</option>
                <option value="	EM CENA ARTE E CIDADANIA">EM CENA ARTE E CIDADANIA</option>
                <option value="	EMANUELA TEREZA BETANCOURT ORTIZ FERREIRA">EMANUELA TEREZA BETANCOURT ORTIZ FERREIRA</option>
                <option value="	EMPODERA">EMPODERA</option>
                <option value="	EMPÓRIO GERMINE">EMPÓRIO GERMINE</option>
                <option value="	EMPREENDE MENINA">EMPREENDE MENINA</option>
                <option value="	EMPREENDEDORISMO VOLUNTARIO">EMPREENDEDORISMO VOLUNTARIO</option>
                <option value="	ENCANTOS INSTITUTO SÓCIO CULTURAL E BENEFICENTE">ENCANTOS INSTITUTO SÓCIO CULTURAL E BENEFICENTE</option>
                <option value="	ENSINAR XADREZ PARA AS CRIANÇAS QUE NECESSITAM UM FUTURO MELHOR">ENSINAR XADREZ PARA AS CRIANÇAS QUE NECESSITAM UM FUTURO MELHOR</option>
                <option value="	ENTREGUE SORRISOS">ENTREGUE SORRISOS</option>
                <option value="	ESCOLA PÚBLICA PRESENTE">ESCOLA PÚBLICA PRESENTE</option>
                <option value="	ESCOLINHA DE FUTEBOL CRAQUE NA BOLA E CRAQUE NA ESCOLA">ESCOLINHA DE FUTEBOL CRAQUE NA BOLA E CRAQUE NA ESCOLA</option>
                <option value="	ESCULTOLAR">ESCULTOLAR</option>
                <option value="	ESPAÇO CULTURAL NOSSA BIBLIOTECA">ESPAÇO CULTURAL NOSSA BIBLIOTECA</option>
                <option value="	ESPAÇO CULTURAL NOVA BONSUCESSO">ESPAÇO CULTURAL NOVA BONSUCESSO</option>
                <option value="	ESPAÇO DE ACOLHIMENTO DA CRIANÇA E DO ADOLESCENTE - EACA">ESPAÇO DE ACOLHIMENTO DA CRIANÇA E DO ADOLESCENTE - EACA</option>
                <option value="	ESPAÇO HUMANA.MENTE PSICOLOGIA SOCIAL E COMUNITÁRIA NA FAVELA">ESPAÇO HUMANA.MENTE PSICOLOGIA SOCIAL E COMUNITÁRIA NA FAVELA</option>
                <option value="	ESPAÇO SOCIAL E CULTURAL É TUDO NOSSO">ESPAÇO SOCIAL E CULTURAL É TUDO NOSSO</option>
                <option value="	ESPORTE E EDUCAÇÃO">ESPORTE E EDUCAÇÃO</option>
                <option value="	ESTA EM ANDAMENTO">ESTA EM ANDAMENTO</option>
                <option value="	EU DIGO NAO AS DROGAS">EU DIGO NAO AS DROGAS</option>
                <option value="	EU VEJO VC SEM FRONTEIRAS">EU VEJO VC SEM FRONTEIRAS</option>
                <option value="	EVOLUIR - INSTITUTO DE EDUCAÇÃO E CIDADANIA">EVOLUIR - INSTITUTO DE EDUCAÇÃO E CIDADANIA</option>
                <option value="	EXITUS INSTITUTO SOCIOAMBIENTAL">EXITUS INSTITUTO SOCIOAMBIENTAL</option>
                <option value="	EXTRAORDINÁRIOS">EXTRAORDINÁRIOS</option>
                <option value="	FALCÕES DO MORRO POAENSE">FALCÕES DO MORRO POAENSE</option>
                <option value="	FALCONS">FALCONS</option>
                <option value="	FAMÍLIA CONSTRUINDO O FUTURO (JUNTOS SOMOS MAIS FORTES)">FAMÍLIA CONSTRUINDO O FUTURO (JUNTOS SOMOS MAIS FORTES)</option>
                <option value="	FAMÍLIA LANATANPA">FAMÍLIA LANATANPA</option>
                <option value="	FAPA - FAÇA ALGO POR ALGUÉM">FAPA - FAÇA ALGO POR ALGUÉM</option>
                <option value="	FAVELA DOS SONHOS">FAVELA DOS SONHOS</option>
                <option value="	FAVELA INC">FAVELA INC</option>
                <option value="	FAVELA RADICAL">FAVELA RADICAL</option>
                <option value="	FAVELA SURF CLUBE">FAVELA SURF CLUBE</option>
                <option value="	FAZER A DIFERENÇA">FAZER A DIFERENÇA</option>
                <option value="	FEDERAÇÃO INTERNACIONAL DE SORVEBOL">FEDERAÇÃO INTERNACIONAL DE SORVEBOL</option>
                <option value="	FELIPE AMAR E VIDA">FELIPE AMAR E VIDA</option>
                <option value="	FENIX - CENTRO CULTURAL DE EDUCACAO E DESENVOLVIMENTO DAS COMUNIDADES">FENIX - CENTRO CULTURAL DE EDUCACAO E DESENVOLVIMENTO DAS COMUNIDADES</option>
                <option value="	FÊNIX - MULHERES GIGANTES">FÊNIX - MULHERES GIGANTES</option>
                <option value="	FLORESCER">FLORESCER</option>
                <option value="	FORTINI INVESTIMENTO SOCIAL">FORTINI INVESTIMENTO SOCIAL</option>
                <option value="	FÓRUM DE JUVENTUDES DO TERRITÓRIO DO BEM">FÓRUM DE JUVENTUDES DO TERRITÓRIO DO BEM</option>
                <option value="	FUNDAÇÃO BENEFICENTE EVANGÉLICA JESUS DE NAZARÉ">FUNDAÇÃO BENEFICENTE EVANGÉLICA JESUS DE NAZARÉ</option>
                <option value="	FUNDACAO BENEFICENTE PRESBITERO FRANCISCO JOAQUIM DA SILVA">FUNDACAO BENEFICENTE PRESBITERO FRANCISCO JOAQUIM DA SILVA</option>
                <option value="	FUNDAÇÃO GETSÊMANI">FUNDAÇÃO GETSÊMANI</option>
                <option value="	FUNDACAO PROJETO DIFERENTE">FUNDACAO PROJETO DIFERENTE</option>
                <option value="	FUTSAL">FUTSAL</option>
                <option value="	GALPÃO CULTURAL - A CASA DO HIP-HOP">GALPÃO CULTURAL - A CASA DO HIP-HOP</option>
                <option value="	GERAÇÃO GK">GERAÇÃO GK</option>
                <option value="	GESCA - GRÊMIO EDUCACIONAL* SOCIAL E CULTURAL ÁGUIAS DE SANTO ANTÔNIO DE JESUS">GESCA - GRÊMIO EDUCACIONAL* SOCIAL E CULTURAL ÁGUIAS DE SANTO ANTÔNIO DE JESUS</option>
                <option value="	GRAM GRUPO DE APOIO À MULHER">GRAM GRUPO DE APOIO À MULHER</option>
                <option value="	GREMIO CULTURAL E RECREATIVO ANJO DA GUARDA">GREMIO CULTURAL E RECREATIVO ANJO DA GUARDA</option>
                <option value="	GREMIO ESTUDANTIL ELITE ECODOM">GREMIO ESTUDANTIL ELITE ECODOM</option>
                <option value="	GREMIO RECREATIVO CULTURAL E ARTE GIGANTE DO SAMBA">GREMIO RECREATIVO CULTURAL E ARTE GIGANTE DO SAMBA</option>
                <option value="	GRUPO ANJOS DA TIA STELLINHA">GRUPO ANJOS DA TIA STELLINHA</option>
                <option value="	GRUPO CENTRAL CULTURA URBANA">GRUPO CENTRAL CULTURA URBANA</option>
                <option value="	GRUPO COMUNITARIO PAZ E AMOR">GRUPO COMUNITARIO PAZ E AMOR</option>
                <option value="	GRUPO COMUNITÁRIO SEMENTE DA ESPERANÇA">GRUPO COMUNITÁRIO SEMENTE DA ESPERANÇA</option>
                <option value="	GRUPO CULTURAL ARTD.RUA">GRUPO CULTURAL ARTD.RUA</option>
                <option value="	GRUPO DE ACAO SOCIAL COMUNITARIA">GRUPO DE ACAO SOCIAL COMUNITARIA</option>
                <option value="	GRUPO DE ASSISTENCIA SOCIAL* CULTURAL* ESPORTES E LAZER - G.A.S">GRUPO DE ASSISTENCIA SOCIAL* CULTURAL* ESPORTES E LAZER - G.A.S</option>
                <option value="	GRUPO ESPIRITA SEARA DA CARIDADE">GRUPO ESPIRITA SEARA DA CARIDADE</option>
                <option value="	GRUPO RESGATE ONG">GRUPO RESGATE ONG</option>
                <option value="	GRUPO SOCIAL PELA VIDA">GRUPO SOCIAL PELA VIDA</option>
                <option value="	GRUPO SOICIAL CULTURAL ILUMINE ESSA IDEIA">GRUPO SOICIAL CULTURAL ILUMINE ESSA IDEIA</option>
                <option value="	GRUPO VIGILANTES DA LUZ AMIGOS DO BEM">GRUPO VIGILANTES DA LUZ AMIGOS DO BEM</option>
                <option value="	HANDEBOL SOCIAL IMBATÍVEIS">HANDEBOL SOCIAL IMBATÍVEIS</option>
                <option value="	HIP HOP SEM FRONTEIRAS">HIP HOP SEM FRONTEIRAS</option>
                <option value="	IASAL">IASAL</option>
                <option value="	ICEF- INSTITUTO DOM">ICEF- INSTITUTO DOM</option>
                <option value="	ICVM">ICVM</option>
                <option value="	IDEAL DO AMANHÃ GUAICUY">IDEAL DO AMANHÃ GUAICUY</option>
                <option value="	IGREJA EVANGELICA PENTECOSTAL FONTE DAS AGUAS VIVAS">IGREJA EVANGELICA PENTECOSTAL FONTE DAS AGUAS VIVAS</option>
                <option value="	IMPACTAÇÃO TRANSFORMAÇÃO SOCIAL">IMPACTAÇÃO TRANSFORMAÇÃO SOCIAL</option>
                <option value="	INCANTO - INSTITUTO DE CULTURA ARTE E NOVAS TECNOLOGIAS">INCANTO - INSTITUTO DE CULTURA ARTE E NOVAS TECNOLOGIAS</option>
                <option value="	INSPIRANDO GERAÇÕES EM AMOR">INSPIRANDO GERAÇÕES EM AMOR</option>
                <option value="	INSTITUIÇÃO CULTURAL TRANSMUTAR GERAÇÃO">INSTITUIÇÃO CULTURAL TRANSMUTAR GERAÇÃO</option>
                <option value="	INSTITUIÇÃO DE INCENTIVO À CRIANÇA E AO ADOLESCENTE DE MOGI MIRIM">INSTITUIÇÃO DE INCENTIVO À CRIANÇA E AO ADOLESCENTE DE MOGI MIRIM</option>
                <option value="	INSTITUIÇÃO SEM FINS LUCRATIVOS">INSTITUIÇÃO SEM FINS LUCRATIVOS</option>
                <option value="	INSTITUIÇÃO SOCIAL DE AMOR CRISTÃO-ISAC">INSTITUIÇÃO SOCIAL DE AMOR CRISTÃO-ISAC</option>
                <option value="	INSTITUIÇÃO SOCIEDADE PLURAL SÃO JOÃO BATISTA / ISP-SJB">INSTITUIÇÃO SOCIEDADE PLURAL SÃO JOÃO BATISTA / ISP-SJB</option>
                <option value="	INSTITUTO + VIDA">INSTITUTO + VIDA</option>
                <option value="	INSTITUTO 100% MORRO">INSTITUTO 100% MORRO</option>
                <option value="	INSTITUTO 7 GERAÇÕES">INSTITUTO 7 GERAÇÕES</option>
                <option value="	INSTITUTO ÁGAPE - CE">INSTITUTO ÁGAPE - CE</option>
                <option value="	INSTITUTO ÁGAPE - RJ">INSTITUTO ÁGAPE - RJ</option>
                <option value="	INSTITUTO AGAPE CHAPADA">INSTITUTO AGAPE CHAPADA</option>
                <option value="	INSTITUTO ÁGAPE MANAUS">INSTITUTO ÁGAPE MANAUS</option>
                <option value="	INSTITUTO AJUDE O PANTANAL OFICIAL">INSTITUTO AJUDE O PANTANAL OFICIAL</option>
                <option value="	INSTITUTO ALMAS AZUIS">INSTITUTO ALMAS AZUIS</option>
                <option value="	INSTITUTO AMAR">INSTITUTO AMAR</option>
                <option value="	INSTITUTO AMARGEN">INSTITUTO AMARGEN</option>
                <option value="	INSTITUTO AME MAIS">INSTITUTO AME MAIS</option>
                <option value="	INSTITUTO AMIGOS DA PERIFERIA">INSTITUTO AMIGOS DA PERIFERIA</option>
                <option value="	INSTITUTO AMPARANDO">INSTITUTO AMPARANDO</option>
                <option value="	INSTITUTO AMPLIANDO HORIZONTES">INSTITUTO AMPLIANDO HORIZONTES</option>
                <option value="	INSTITUTO ANA RIBEIRO DO SUBURBIO FERROVIARIO">INSTITUTO ANA RIBEIRO DO SUBURBIO FERROVIARIO</option>
                <option value="	INSTITUTO ANALICE MULHERES DE MAOS DADAS">INSTITUTO ANALICE MULHERES DE MAOS DADAS</option>
                <option value="	INSTITUTO ANDORINHAS BALTAZAR">INSTITUTO ANDORINHAS BALTAZAR</option>
                <option value="	INSTITUTO ANDRE DE SOUZA">INSTITUTO ANDRE DE SOUZA</option>
                <option value="	INSTITUTO ANTÔNIO COELHO DE CASTRO">INSTITUTO ANTÔNIO COELHO DE CASTRO</option>
                <option value="	INSTITUTO APROMEP">INSTITUTO APROMEP</option>
                <option value="	INSTITUTO ARENA">INSTITUTO ARENA</option>
                <option value="	INSTITUTO ÁRVORE DA VIDA">INSTITUTO ÁRVORE DA VIDA</option>
                <option value="	INSTITUTO AS VALQUIRIAS">INSTITUTO AS VALQUIRIAS</option>
                <option value="	INSTITUTO ASCENDENDO MENTES">INSTITUTO ASCENDENDO MENTES</option>
                <option value="	INSTITUTO ATITUDE SP">INSTITUTO ATITUDE SP</option>
                <option value="	INSTITUTO ATLETAS DE OURO DO JARDIM GRAMACHO">INSTITUTO ATLETAS DE OURO DO JARDIM GRAMACHO</option>
                <option value="	INSTITUTO AVIVAH">INSTITUTO AVIVAH</option>
                <option value="	INSTITUTO AYÓ">INSTITUTO AYÓ</option>
                <option value="	INSTITUTO BATISTA SIMON PR SIMON HORBACZYK">INSTITUTO BATISTA SIMON PR SIMON HORBACZYK</option>
                <option value="	INSTITUTO BATUCANDO A ESPERANÇA">INSTITUTO BATUCANDO A ESPERANÇA</option>
                <option value="	INSTITUTO BENEFICENTE MULHERES EM AÇÃO">INSTITUTO BENEFICENTE MULHERES EM AÇÃO</option>
                <option value="	INSTITUTO BRASILEIRO DE EXECUTIVOS DE FINANÇAS  DO PARANÁ">INSTITUTO BRASILEIRO DE EXECUTIVOS DE FINANÇAS  DO PARANÁ</option>
                <option value="	INSTITUTO BRASILEIRO DE EXPEDIÇÕES SOCIAIS">INSTITUTO BRASILEIRO DE EXPEDIÇÕES SOCIAIS</option>
                <option value="	INSTITUTO BRAULINO DE SOUZA NASCIMENTO">INSTITUTO BRAULINO DE SOUZA NASCIMENTO</option>
                <option value="	INSTITUTO BÚSSOLA JOVEM">INSTITUTO BÚSSOLA JOVEM</option>
                <option value="	INSTITUTO CANGAÇO FIGHT">INSTITUTO CANGAÇO FIGHT</option>
                <option value="	INSTITUTO CARANGONDÉ CIDADANIA">INSTITUTO CARANGONDÉ CIDADANIA</option>
                <option value="	INSTITUTO CASA DE BARRO - CULTURA ARTE EDUCAÇÃO">INSTITUTO CASA DE BARRO - CULTURA ARTE EDUCAÇÃO</option>
                <option value="	INSTITUTO CAUSADORES DA ALEGRIA">INSTITUTO CAUSADORES DA ALEGRIA</option>
                <option value="	INSTITUTO CEGONHA">INSTITUTO CEGONHA</option>
                <option value="	INSTITUTO CONERAGIR">INSTITUTO CONERAGIR</option>
                <option value="	INSTITUTO CONEXÃO DORCAS">INSTITUTO CONEXÃO DORCAS</option>
                <option value="	INSTITUTO CONFORME">INSTITUTO CONFORME</option>
                <option value="	INSTITUTO CONQUIST">INSTITUTO CONQUIST</option>
                <option value="	INSTITUTO CONVERGIR">INSTITUTO CONVERGIR</option>
                <option value="	INSTITUTO CORES DO MARÁ">INSTITUTO CORES DO MARÁ</option>
                <option value="	INSTITUTO CPL PARÁ DE MINAS">INSTITUTO CPL PARÁ DE MINAS</option>
                <option value="	INSTITUTO CRIANÇA MAIS FELIZ RS">INSTITUTO CRIANÇA MAIS FELIZ RS</option>
                <option value="	INSTITUTO CRISTIANE CAMARGO">INSTITUTO CRISTIANE CAMARGO</option>
                <option value="	INSTITUTO CULTURAL ANÍBAL MACHADO">INSTITUTO CULTURAL ANÍBAL MACHADO</option>
                <option value="	INSTITUTO CULTURAL BALUARTE DA AMAZONIA - ICBA">INSTITUTO CULTURAL BALUARTE DA AMAZONIA - ICBA</option>
                <option value="	INSTITUTO DA CRIANCA ALBERTO DE MOURA">INSTITUTO DA CRIANCA ALBERTO DE MOURA</option>
                <option value="	INSTITUTO DAH ARAUJO">INSTITUTO DAH ARAUJO</option>
                <option value="	INSTITUTO DAS ÁGUIAS">INSTITUTO DAS ÁGUIAS</option>
                <option value="	INSTITUTO DE :RESPONSABILIDADE E INVESTIMENTO SOCIAL IRIS">INSTITUTO DE :RESPONSABILIDADE E INVESTIMENTO SOCIAL IRIS</option>
                <option value="	INSTITUTO DE ARTE E CIDADANIA DO CEARÁ">INSTITUTO DE ARTE E CIDADANIA DO CEARÁ</option>
                <option value="	INSTITUTO DE ARTE E EDUCAÇÃO DA BAHIA">INSTITUTO DE ARTE E EDUCAÇÃO DA BAHIA</option>
                <option value="	INSTITUTO DE ARTES E DANCAS O SENHOR E CONTIGO">INSTITUTO DE ARTES E DANCAS O SENHOR E CONTIGO</option>
                <option value="	INSTITUTO DE ASSISTÊNCIA À VIDA KASA DI SARDINHA">INSTITUTO DE ASSISTÊNCIA À VIDA KASA DI SARDINHA</option>
                <option value="	INSTITUTO DE ATIVISMO COMUNITARIO - REDE MOBILIZE">INSTITUTO DE ATIVISMO COMUNITARIO - REDE MOBILIZE</option>
                <option value="	INSTITUTO DE CAPACITAÇÃO E AÇÃO SOCIAL O GRITO">INSTITUTO DE CAPACITAÇÃO E AÇÃO SOCIAL O GRITO</option>
                <option value="	INSTITUTO DE CIDADANIA AMOR AO PRÓXIMO">INSTITUTO DE CIDADANIA AMOR AO PRÓXIMO</option>
                <option value="	INSTITUTO DE CULTURA E LAZER EBENÉZER">INSTITUTO DE CULTURA E LAZER EBENÉZER</option>
                <option value="	INSTITUTO DE DEFESA DA CIDADANIA COMUNITARIA - IDECC">INSTITUTO DE DEFESA DA CIDADANIA COMUNITARIA - IDECC</option>
                <option value="	INSTITUTO DE DESENVOLVIMENTO ARTÍSTICO E CULTURAL DO CEARÁ">INSTITUTO DE DESENVOLVIMENTO ARTÍSTICO E CULTURAL DO CEARÁ</option>
                <option value="	INSTITUTO DE DESENVOLVIMENTO E PROTEÇÃO SOCIAL">INSTITUTO DE DESENVOLVIMENTO E PROTEÇÃO SOCIAL</option>
                <option value="	INSTITUTO DE DESENVOLVIMENTO ESPORTIVO* SOCIAL E CULTURAL IMPACTANDO GERAÇÕES">INSTITUTO DE DESENVOLVIMENTO ESPORTIVO* SOCIAL E CULTURAL IMPACTANDO GERAÇÕES</option>
                <option value="	INSTITUTO DE DESENVOLVIMENTO HUMANO E SOCIAL PELO ESPORTE EDUCAÇÃO CULTURA E CIDADANIA">INSTITUTO DE DESENVOLVIMENTO HUMANO E SOCIAL PELO ESPORTE EDUCAÇÃO CULTURA E CIDADANIA</option>
                <option value="	INSTITUTO DE DESENVOLVIMENTO PESSOAL E SOCIAL OS SONHADORES">INSTITUTO DE DESENVOLVIMENTO PESSOAL E SOCIAL OS SONHADORES</option>
                <option value="	INSTITUTO DE DESENVOLVIMENTO SOCIOAMBIENTAL JOAO DE BARRO">INSTITUTO DE DESENVOLVIMENTO SOCIOAMBIENTAL JOAO DE BARRO</option>
                <option value="	INSTITUTO DE DESENVOLVIMENTO SUSTENTAVEL CONEXAO">INSTITUTO DE DESENVOLVIMENTO SUSTENTAVEL CONEXAO</option>
                <option value="	INSTITUTO DE EDUCACAO CULTURA E MINISTERIO EDUCARE">INSTITUTO DE EDUCACAO CULTURA E MINISTERIO EDUCARE</option>
                <option value="	INSTITUTO DE EDUCACAO E CULTURA DE EQUADOR - IECE">INSTITUTO DE EDUCACAO E CULTURA DE EQUADOR - IECE</option>
                <option value="	INSTITUTO DE ESTUDOS E PRESERVAÇÃO DAS CULTURAS INDÊ­GENAS BRASILEIRAS">INSTITUTO DE ESTUDOS E PRESERVAÇÃO DAS CULTURAS INDÊ­GENAS BRASILEIRAS</option>
                <option value="	INSTITUTO DE SOLIDARIEDADE E RESGATE DA CIDADANIA RUMO AO FUTURO">INSTITUTO DE SOLIDARIEDADE E RESGATE DA CIDADANIA RUMO AO FUTURO</option>
                <option value="	INSTITUTO DESENVOLVIMENTO ESPERANÇA">INSTITUTO DESENVOLVIMENTO ESPERANÇA</option>
                <option value="	INSTITUTO DICA FERREIRA">INSTITUTO DICA FERREIRA</option>
                <option value="	INSTITUTO DO TRABALHO">INSTITUTO DO TRABALHO</option>
                <option value="	INSTITUTO DOANDO QUE SE RECEBE">INSTITUTO DOANDO QUE SE RECEBE</option>
                <option value="	INSTITUTO DONA MARIA">INSTITUTO DONA MARIA</option>
                <option value="	INSTITUTO É POSSÍVEL SONHAR">INSTITUTO É POSSÍVEL SONHAR</option>
                <option value="	INSTITUTO ECLESIA MOVEMENT">INSTITUTO ECLESIA MOVEMENT</option>
                <option value="	INSTITUTO EDSON ROYER">INSTITUTO EDSON ROYER</option>
                <option value="	INSTITUTO EDUCA MAIS ESPORTE">INSTITUTO EDUCA MAIS ESPORTE</option>
                <option value="	INSTITUTO EDUCACIONAL ASSISTENCIAL COROADINHO">INSTITUTO EDUCACIONAL ASSISTENCIAL COROADINHO</option>
                <option value="	INSTITUTO EDUCACIONAL DE BOMBEIROS CIVIL DO MARANHAO">INSTITUTO EDUCACIONAL DE BOMBEIROS CIVIL DO MARANHAO</option>
                <option value="	INSTITUTO EDUCACIONAL NOSSA SENHORA DE FÁTIMA">INSTITUTO EDUCACIONAL NOSSA SENHORA DE FÁTIMA</option>
                <option value="	INSTITUTO EDUCAR GOLANDIM">INSTITUTO EDUCAR GOLANDIM</option>
                <option value="	INSTITUTO EMARCA DE PESQUISA E EDUCACAO PROFESSIONAL">INSTITUTO EMARCA DE PESQUISA E EDUCACAO PROFESSIONAL</option>
                <option value="	INSTITUTO EMPREGA+">INSTITUTO EMPREGA+</option>
                <option value="	INSTITUTO ENTRE ASPAS DE AÇÃO COMUNITARIA">INSTITUTO ENTRE ASPAS DE AÇÃO COMUNITARIA</option>
                <option value="	INSTITUTO ENTRE O CÉU E A FAVELA PARA ARTE EDUCAÇÃO E CIDADANIA">INSTITUTO ENTRE O CÉU E A FAVELA PARA ARTE EDUCAÇÃO E CIDADANIA</option>
                <option value="	INSTITUTO ENTREASPAS DE ACAO COMUNITARIA">INSTITUTO ENTREASPAS DE ACAO COMUNITARIA</option>
                <option value="	INSTITUTO EPURANIOS">INSTITUTO EPURANIOS</option>
                <option value="	INSTITUTO ESPERANÇA">INSTITUTO ESPERANÇA</option>
                <option value="	INSTITUTO ESPERANCA DO BRASIL">INSTITUTO ESPERANCA DO BRASIL</option>
                <option value="	INSTITUTO EU FACO O BEM">INSTITUTO EU FACO O BEM</option>
                <option value="	INSTITUTO F.S.D.">INSTITUTO F.S.D.</option>
                <option value="	INSTITUTO FÁBRICA DE VENCEDOR">INSTITUTO FÁBRICA DE VENCEDOR</option>
                <option value="	INSTITUTO FAMÍLIA CHEGADOS">INSTITUTO FAMÍLIA CHEGADOS</option>
                <option value="	INSTITUTO FAMÍLIA CRIATIVA DO CAMPO">INSTITUTO FAMÍLIA CRIATIVA DO CAMPO</option>
                <option value="	INSTITUTO FAVELARTE">INSTITUTO FAVELARTE</option>
                <option value="	INSTITUTO FELIZ CIDADE">INSTITUTO FELIZ CIDADE</option>
                <option value="	INSTITUTO FLORIANO PEÇANHA DOS SANTOS">INSTITUTO FLORIANO PEÇANHA DOS SANTOS</option>
                <option value="	INSTITUTO FORMANDO VENCEDORES">INSTITUTO FORMANDO VENCEDORES</option>
                <option value="	INSTITUTO GERAÇÃO 4">INSTITUTO GERAÇÃO 4</option>
                <option value="	INSTITUTO GERAÇÃO TATAMI">INSTITUTO GERAÇÃO TATAMI</option>
                <option value="	INSTITUTO GERANDO FALCÕES - UNIDADE FERRAZ">INSTITUTO GERANDO FALCÕES - UNIDADE FERRAZ</option>
                <option value="	INSTITUTO GERANDO FALCÕES - UNIDADE POÁ">INSTITUTO GERANDO FALCÕES - UNIDADE POÁ</option>
                <option value="	INSTITUTO GERANDO FALCÕES - UNIDADE SUZANO">INSTITUTO GERANDO FALCÕES - UNIDADE SUZANO</option>
                <option value="	INSTITUTO GERANDO SONHOS">INSTITUTO GERANDO SONHOS</option>
                <option value="	INSTITUTO GILGAL">INSTITUTO GILGAL</option>
                <option value="	INSTITUTO GIRASSOL DE DESENVOLVIMENTO SOCIAL">INSTITUTO GIRASSOL DE DESENVOLVIMENTO SOCIAL</option>
                <option value="	INSTITUTO HA ESPERANCA">INSTITUTO HA ESPERANCA</option>
                <option value="	INSTITUTO HERDAR">INSTITUTO HERDAR</option>
                <option value="	INSTITUTO HEROIS E PRINCESAS DA ALEGRIA">INSTITUTO HEROIS E PRINCESAS DA ALEGRIA</option>
                <option value="	INSTITUTO IMPROVAVEIS DA VIDA">INSTITUTO IMPROVAVEIS DA VIDA</option>
                <option value="	INSTITUTO INCLUA">INSTITUTO INCLUA</option>
                <option value="	INSTITUTO INOVAÇÃO SUSTENTÁVEL - ONG MENSAGEIROS DA ESPERANÇA">INSTITUTO INOVAÇÃO SUSTENTÁVEL - ONG MENSAGEIROS DA ESPERANÇA</option>
                <option value="	INSTITUTO JOAQUIM TÁVORA">INSTITUTO JOAQUIM TÁVORA</option>
                <option value="	INSTITUTO JOSEFINA BAKHITA">INSTITUTO JOSEFINA BAKHITA</option>
                <option value="	INSTITUTO JUDO REGASTANDO VIDAS">INSTITUTO JUDO REGASTANDO VIDAS</option>
                <option value="	INSTITUTO JUNTOS SOMOS MAIS FORTES">INSTITUTO JUNTOS SOMOS MAIS FORTES</option>
                <option value="	INSTITUTO JUVENTUDE CRIATIVA">INSTITUTO JUVENTUDE CRIATIVA</option>
                <option value="	INSTITUTO KALEO">INSTITUTO KALEO</option>
                <option value="	INSTITUTO KATIANA PENA">INSTITUTO KATIANA PENA</option>
                <option value="	INSTITUTO LAR - LEVANTE, ANDE E RECOMECE">INSTITUTO LAR - LEVANTE, ANDE E RECOMECE</option>
                <option value="	INSTITUTO LIRA DE INCLUSÃO SOCIAL - ILIS">INSTITUTO LIRA DE INCLUSÃO SOCIAL - ILIS</option>
                <option value="	INSTITUTO LIVROS EM TODO LUGAR">INSTITUTO LIVROS EM TODO LUGAR</option>
                <option value="	INSTITUTO MACUNAÍMA DE CULTURA - ESCOLA DE CIDADANIA">INSTITUTO MACUNAÍMA DE CULTURA - ESCOLA DE CIDADANIA</option>
                <option value="	INSTITUTO MAGNIFICAT DE DESENVOLVIMENTO HUMANO E DE PROTEÇÃO AMBIENTAL">INSTITUTO MAGNIFICAT DE DESENVOLVIMENTO HUMANO E DE PROTEÇÃO AMBIENTAL</option>
                <option value="	INSTITUTO MAIS MULHER">INSTITUTO MAIS MULHER</option>
                <option value="	INSTITUTO MAIS VIDA">INSTITUTO MAIS VIDA</option>
                <option value="	INSTITUTO MANCALA">INSTITUTO MANCALA</option>
                <option value="	INSTITUTO MANDAVER">INSTITUTO MANDAVER</option>
                <option value="	INSTITUTO MANUEL BRAGA">INSTITUTO MANUEL BRAGA</option>
                <option value="	INSTITUTO MARANHENSE EDUCANDÁRIO BETESDA">INSTITUTO MARANHENSE EDUCANDÁRIO BETESDA</option>
                <option value="	INSTITUTO MARCINHO MEGAS KAMARADAS">INSTITUTO MARCINHO MEGAS KAMARADAS</option>
                <option value="	INSTITUTO MARIA EDUARDA">INSTITUTO MARIA EDUARDA</option>
                <option value="	INSTITUTO MARIANA">INSTITUTO MARIANA</option>
                <option value="	INSTITUTO MESTRE NORDESTINO">INSTITUTO MESTRE NORDESTINO</option>
                <option value="	INSTITUTO METOCHI">INSTITUTO METOCHI</option>
                <option value="	INSTITUTO MIGUEL FERNANDES TORRES">INSTITUTO MIGUEL FERNANDES TORRES</option>
                <option value="	INSTITUTO MISTURAÍ">INSTITUTO MISTURAÍ</option>
                <option value="	INSTITUTO MOVER VIDAS">INSTITUTO MOVER VIDAS</option>
                <option value="	INSTITUTO MOVIMENTO PARA A VIDA">INSTITUTO MOVIMENTO PARA A VIDA</option>
                <option value="	INSTITUTO MUCAMBO">INSTITUTO MUCAMBO</option>
                <option value="	INSTITUTO MUNDIAL SAO LAZARO DE APOIO A CRIANCA E ADOLESCENTE">INSTITUTO MUNDIAL SAO LAZARO DE APOIO A CRIANCA E ADOLESCENTE</option>
                <option value="	INSTITUTO MUNDO NOVO DA CULTURA VIVA">INSTITUTO MUNDO NOVO DA CULTURA VIVA</option>
                <option value="	INSTITUTO NACIONAL DE DESENVOLVIMENTO SOCIAL E QUALIFICAÇÃO PROFISSIONAL">INSTITUTO NACIONAL DE DESENVOLVIMENTO SOCIAL E QUALIFICAÇÃO PROFISSIONAL</option>
                <option value="	INSTITUTO NEOQAV">INSTITUTO NEOQAV</option>
                <option value="	INSTITUTO NOVA AMAZONIA - INA">INSTITUTO NOVA AMAZONIA - INA</option>
                <option value="	INSTITUTO NOVA UNIÃO DA ARTE">INSTITUTO NOVA UNIÃO DA ARTE</option>
                <option value="	INSTITUTO NOVO HORIZONTE - INH">INSTITUTO NOVO HORIZONTE - INH</option>
                <option value="	INSTITUTO O CANTO DO PATATIVA">INSTITUTO O CANTO DO PATATIVA</option>
                <option value="	INSTITUTO PAPEL DE MENINO">INSTITUTO PAPEL DE MENINO</option>
                <option value="	INSTITUTO PASSO DE ANJO">INSTITUTO PASSO DE ANJO</option>
                <option value="	INSTITUTO PATERNUS">INSTITUTO PATERNUS</option>
                <option value="	INSTITUTO PEB _PROJETO EDUCA BASQUETE">INSTITUTO PEB _PROJETO EDUCA BASQUETE</option>
                <option value="	INSTITUTO PELICANO">INSTITUTO PELICANO</option>
                <option value="	INSTITUTO PORTAL DAS CORES">INSTITUTO PORTAL DAS CORES</option>
                <option value="	INSTITUTO PORTAL SOCIAL DA PONTE">INSTITUTO PORTAL SOCIAL DA PONTE</option>
                <option value="	INSTITUTO PRA TODO MUNDO">INSTITUTO PRA TODO MUNDO</option>
                <option value="	INSTITUTO PRETA VELHA">INSTITUTO PRETA VELHA</option>
                <option value="	INSTITUTO PRIMEIRO ESTÁGIO DE ESPORTE CULTURA E EDUCAÇÃO">INSTITUTO PRIMEIRO ESTÁGIO DE ESPORTE CULTURA E EDUCAÇÃO</option>
                <option value="	INSTITUTO PRO MAIS">INSTITUTO PRO MAIS</option>
                <option value="	INSTITUTO PROJETO CRIA - CULTURA E EDUCAÇÃO">INSTITUTO PROJETO CRIA - CULTURA E EDUCAÇÃO</option>
                <option value="	INSTITUTO QUADRO DE ESPERANÇA">INSTITUTO QUADRO DE ESPERANÇA</option>
                <option value="	INSTITUTO QUINTAL TIA JURA">INSTITUTO QUINTAL TIA JURA</option>
                <option value="	INSTITUTO RAHAMIM">INSTITUTO RAHAMIM</option>
                <option value="	INSTITUTO RAÍZES">INSTITUTO RAÍZES</option>
                <option value="	INSTITUTO RASTRO">INSTITUTO RASTRO</option>
                <option value="	INSTITUTO RECOMEÇAR">INSTITUTO RECOMEÇAR</option>
                <option value="	INSTITUTO REDENCAO">INSTITUTO REDENCAO</option>
                <option value="	INSTITUTO REGGARTE">INSTITUTO REGGARTE</option>
                <option value="	INSTITUTO RESGATA CIDADAO">INSTITUTO RESGATA CIDADAO</option>
                <option value="	INSTITUTO RESGATANDO A ESSÊNCIA">INSTITUTO RESGATANDO A ESSÊNCIA</option>
                <option value="	INSTITUTO RESGATANDO VIDAS">INSTITUTO RESGATANDO VIDAS</option>
                <option value="	INSTITUTO RESGATANDO VIDAS PARA VIDA">INSTITUTO RESGATANDO VIDAS PARA VIDA</option>
                <option value="	INSTITUTO REVERBERA">INSTITUTO REVERBERA</option>
                <option value="	INSTITUTO REVIVER">INSTITUTO REVIVER</option>
                <option value="	INSTITUTO REVIVER MIRAI">INSTITUTO REVIVER MIRAI</option>
                <option value="	INSTITUTO RIPAXOTE DE CULTURA">INSTITUTO RIPAXOTE DE CULTURA</option>
                <option value="	INSTITUTO ROSA REVIVER">INSTITUTO ROSA REVIVER</option>
                <option value="	INSTITUTO ROSEMERE CHAVES">INSTITUTO ROSEMERE CHAVES</option>
                <option value="	INSTITUTO RUGBY PARA TODOS">INSTITUTO RUGBY PARA TODOS</option>
                <option value="	INSTITUTO S.O.S PERIFERIA">INSTITUTO S.O.S PERIFERIA</option>
                <option value="	INSTITUTO SAINT NICHOLAS CARE">INSTITUTO SAINT NICHOLAS CARE</option>
                <option value="	INSTITUTO SANTA LUZIA">INSTITUTO SANTA LUZIA</option>
                <option value="	INSTITUTO SÃO JORGE">INSTITUTO SÃO JORGE</option>
                <option value="	INSTITUTO SEED">INSTITUTO SEED</option>
                <option value="	INSTITUTO SEJA LUZ, YURI REIS">INSTITUTO SEJA LUZ, YURI REIS</option>
                <option value="	INSTITUTO SEMA SEMEANDO AMOR, TRANSFORMANDO VIDAS">INSTITUTO SEMA SEMEANDO AMOR, TRANSFORMANDO VIDAS</option>
                <option value="	INSTITUTO SEMEAR PLANTAR E TRANSFORMAR">INSTITUTO SEMEAR PLANTAR E TRANSFORMAR</option>
                <option value="	INSTITUTO SEMENTINHA PARA EDUCAÇÃO E PROTEÇÃO A CRIANÇA E AO ADOLESCENTE">INSTITUTO SEMENTINHA PARA EDUCAÇÃO E PROTEÇÃO A CRIANÇA E AO ADOLESCENTE</option>
                <option value="	INSTITUTO SILVEIRA MARQUES">INSTITUTO SILVEIRA MARQUES</option>
                <option value="	INSTITUTO SÓ ALEGRIA">INSTITUTO SÓ ALEGRIA</option>
                <option value="	INSTITUTO SOCIAL ÁGATHA EM DEFESA DA MULHER">INSTITUTO SOCIAL ÁGATHA EM DEFESA DA MULHER</option>
                <option value="	INSTITUTO SOCIAL CORRENTE DO AMOR">INSTITUTO SOCIAL CORRENTE DO AMOR</option>
                <option value="	INSTITUTO SOCIAL CULTURAL DE APOIO À VIDA PADRE ALCIDES TRES">INSTITUTO SOCIAL CULTURAL DE APOIO À VIDA PADRE ALCIDES TRES</option>
                <option value="	INSTITUTO SOCIAL DE ASSISTÊNCIA COMUNITÁRIA MÃO AMIGA">INSTITUTO SOCIAL DE ASSISTÊNCIA COMUNITÁRIA MÃO AMIGA</option>
                <option value="	INSTITUTO SOCIAL DE TODO CORAÇÃO">INSTITUTO SOCIAL DE TODO CORAÇÃO</option>
                <option value="	INSTITUTO SOCIAL DO BRASIL">INSTITUTO SOCIAL DO BRASIL</option>
                <option value="	INSTITUTO SOCIAL E COMUNITÁRIO ARRAIAL DA GLÓRIA">INSTITUTO SOCIAL E COMUNITÁRIO ARRAIAL DA GLÓRIA</option>
                <option value="	INSTITUTO SOCIAL EDUCACIONAL CULTURAL ECOFUTURO">INSTITUTO SOCIAL EDUCACIONAL CULTURAL ECOFUTURO</option>
                <option value="	INSTITUTO SOCIAL FONTE DE LUZ">INSTITUTO SOCIAL FONTE DE LUZ</option>
                <option value="	INSTITUTO SOCIAL IDE BR">INSTITUTO SOCIAL IDE BR</option>
                <option value="	INSTITUTO SOCIAL KADOSH">INSTITUTO SOCIAL KADOSH</option>
                <option value="	INSTITUTO SOCIAL NOVO OLHAR">INSTITUTO SOCIAL NOVO OLHAR</option>
                <option value="	INSTITUTO SOCIAL NOVOLHAR">INSTITUTO SOCIAL NOVOLHAR</option>
                <option value="	INSTITUTO SOCIAL, ANA MARIA SANTOS - (INSTITUTO MAEZONA)">INSTITUTO SOCIAL, ANA MARIA SANTOS - (INSTITUTO MAEZONA)</option>
                <option value="	INSTITUTO SOCIO EDUCACIONAL E TECNOLOGICO SEMEAR">INSTITUTO SOCIO EDUCACIONAL E TECNOLOGICO SEMEAR</option>
                <option value="	INSTITUTO SOCIOAMBIENTAL DAS VERTENTES">INSTITUTO SOCIOAMBIENTAL DAS VERTENTES</option>
                <option value="	INSTITUTO SOCIOCULTURAL ECOVIDA">INSTITUTO SOCIOCULTURAL ECOVIDA</option>
                <option value="	INSTITUTO SOCIOEMOCIONAL IRMÃS COACH">INSTITUTO SOCIOEMOCIONAL IRMÃS COACH</option>
                <option value="	INSTITUTO SOMOS ÁGAPE">INSTITUTO SOMOS ÁGAPE</option>
                <option value="	INSTITUTO SONHAR ALTO">INSTITUTO SONHAR ALTO</option>
                <option value="	INSTITUTO SONHE">INSTITUTO SONHE</option>
                <option value="	INSTITUTO SOS IRMÃOS CARENTES">INSTITUTO SOS IRMÃOS CARENTES</option>
                <option value="	INSTITUTO SOS REVIVER">INSTITUTO SOS REVIVER</option>
                <option value="	INSTITUTO SUBLIM">INSTITUTO SUBLIM</option>
                <option value="	INSTITUTO SUPERAÇÃO RUBENS AZEVEDO SECATO">INSTITUTO SUPERAÇÃO RUBENS AZEVEDO SECATO</option>
                <option value="	INSTITUTO TCHIBUM">INSTITUTO TCHIBUM</option>
                <option value="	INSTITUTO TIA FLÁVIA">INSTITUTO TIA FLÁVIA</option>
                <option value="	INSTITUTO TIBAGI">INSTITUTO TIBAGI</option>
                <option value="	INSTITUTO TRANSFORMAR">INSTITUTO TRANSFORMAR</option>
                <option value="	INSTITUTO TREM DAS ONZE NOVA VIDA">INSTITUTO TREM DAS ONZE NOVA VIDA</option>
                <option value="	INSTITUTO USINA DOS ATOS">INSTITUTO USINA DOS ATOS</option>
                <option value="	INSTITUTO VERDE CRIANDO VIDAS">INSTITUTO VERDE CRIANDO VIDAS</option>
                <option value="	INSTITUTO VID.ART">INSTITUTO VID.ART</option>
                <option value="	INSTITUTO VIDA REAL">INSTITUTO VIDA REAL</option>
                <option value="	INSTITUTO VIDAS PELO FUTURO">INSTITUTO VIDAS PELO FUTURO</option>
                <option value="	INSTITUTO VIRANDO O JOGO">INSTITUTO VIRANDO O JOGO</option>
                <option value="	INSTITUTO VIVER A VIDA">INSTITUTO VIVER A VIDA</option>
                <option value="	INSTITUTO VOCÊ EM CENA">INSTITUTO VOCÊ EM CENA</option>
                <option value="	INSTITUTOMULHERES EM SUPERAÇÃO NA AMAZÔNIA">INSTITUTOMULHERES EM SUPERAÇÃO NA AMAZÔNIA</option>
                <option value="	INSTUTUTO CULTURAL E SOCIAL COLORADO">INSTUTUTO CULTURAL E SOCIAL COLORADO</option>
                <option value="	INTERLIGA">INTERLIGA</option>
                <option value="	INTITUTO GERAÇÃO ELEITA MOVA-SE.">INTITUTO GERAÇÃO ELEITA MOVA-SE.</option>
                <option value="	INTITUTO NACIONAL LAR DOS SONHOS">INTITUTO NACIONAL LAR DOS SONHOS</option>
                <option value="	INVESTIDORES DA ESPERANÇA">INVESTIDORES DA ESPERANÇA</option>
                <option value="	IRSEBA INSTITUTO DE REFERENCIA SOCIAL DO ESTADO DA BAHIA">IRSEBA INSTITUTO DE REFERENCIA SOCIAL DO ESTADO DA BAHIA</option>
                <option value="	JAMPA INVISÍVEL">JAMPA INVISÍVEL</option>
                <option value="	JB AGUIA INSTITUTO DE APOIO SOCIAL">JB AGUIA INSTITUTO DE APOIO SOCIAL</option>
                <option value="	JIU-JITSU PARA TODOS">JIU-JITSU PARA TODOS</option>
                <option value="	JOHNY EVERTON FIRMINO DA SILVA">JOHNY EVERTON FIRMINO DA SILVA</option>
                <option value="	JOSÉ JUNIOR TEIXEIRA">JOSÉ JUNIOR TEIXEIRA</option>
                <option value="	JOVEM VIDA PLENA">JOVEM VIDA PLENA</option>
                <option value="	JUDO UNIAO DE GUAIANASES">JUDO UNIAO DE GUAIANASES</option>
                <option value="	JUNTA QUE AJUDA">JUNTA QUE AJUDA</option>
                <option value="	JUVENTUDE RAÇA">JUVENTUDE RAÇA</option>
                <option value="	KILOMBECO">KILOMBECO</option>
                <option value="	KYRIUS CIA DE ARTES">KYRIUS CIA DE ARTES</option>
                <option value="	LAPIDANDO TALENTOS NA QUEBRADA - ( ALTERANDO PARA PROJETO BASE)">LAPIDANDO TALENTOS NA QUEBRADA - ( ALTERANDO PARA PROJETO BASE)</option>
                <option value="	LAR BATISTA DE CRIANCAS">LAR BATISTA DE CRIANCAS</option>
                <option value="	LARISSA SIQUEIRA PONTES 07146589447">LARISSA SIQUEIRA PONTES 07146589447</option>
                <option value="	LAURB - INSTITUTO DE URBANISMO ECOSSISTÊMICO">LAURB - INSTITUTO DE URBANISMO ECOSSISTÊMICO</option>
                <option value="	LEIA E APRENDA">LEIA E APRENDA</option>
                <option value="	LIA GERANDO VIDAS">LIA GERANDO VIDAS</option>
                <option value="	LIONS CLUBE DE NATAL FUTURAS GERAÇÕES">LIONS CLUBE DE NATAL FUTURAS GERAÇÕES</option>
                <option value="	LOBO & DUREY ARQUITETURA LTDA.">LOBO & DUREY ARQUITETURA LTDA.</option>
                <option value="	LOMAR LAR AONDE MORA O AMOR">LOMAR LAR AONDE MORA O AMOR</option>
                <option value="	M.A.L.O.C.A.( MOVIMENTO ARTICULADO LIBERTÁRIO ORGANIZADO EM PROL DA CIDADANIA E DO APOIO SOCIAL)">M.A.L.O.C.A.( MOVIMENTO ARTICULADO LIBERTÁRIO ORGANIZADO EM PROL DA CIDADANIA E DO APOIO SOCIAL)</option>
                <option value="	MAAC - MOVIMENTO DE ARTICULAÇÃO AMBIENTAL E CULTURAL">MAAC - MOVIMENTO DE ARTICULAÇÃO AMBIENTAL E CULTURAL</option>
                <option value="	MAANAIM AÇÃO SOCIAL">MAANAIM AÇÃO SOCIAL</option>
                <option value="	MANIFESTA MOVEMENT">MANIFESTA MOVEMENT</option>
                <option value="	MÃO AMIGA">MÃO AMIGA</option>
                <option value="	MARGARIDAS PROJETOS PRA VIDA">MARGARIDAS PROJETOS PRA VIDA</option>
                <option value="	MATRIARCAS EM AÇAO">MATRIARCAS EM AÇAO</option>
                <option value="	MENINOS DA BOLA">MENINOS DA BOLA</option>
                <option value="	MENTES BRILHANTES F.S">MENTES BRILHANTES F.S</option>
                <option value="	METAMORFOSE">METAMORFOSE</option>
                <option value="	MINISTERIO INTERNACIONAL DE MISERICORDIA">MINISTERIO INTERNACIONAL DE MISERICORDIA</option>
                <option value="	MISSÃO CASA GAIO">MISSÃO CASA GAIO</option>
                <option value="	MISSÃO EDEDE MELEQUE">MISSÃO EDEDE MELEQUE</option>
                <option value="	MISSÃO SERTÃO">MISSÃO SERTÃO</option>
                <option value="	MOTIRÔ">MOTIRÔ</option>
                <option value="	MOVIMENTANDO VIDAS">MOVIMENTANDO VIDAS</option>
                <option value="	MOVIMENTO DE ASSISTÊNCIA E INCLUSÃO SOCIAL - CONSULTORIA SOCIAL">MOVIMENTO DE ASSISTÊNCIA E INCLUSÃO SOCIAL - CONSULTORIA SOCIAL</option>
                <option value="	MOVIMENTO DE MULHERES DE APOIO HUMANITARIO">MOVIMENTO DE MULHERES DE APOIO HUMANITARIO</option>
                <option value="	MOVIMENTO INSPIRE">MOVIMENTO INSPIRE</option>
                <option value="	MOVIMENTO SOCIAL E CULTURAL CORES DO AMANHÃ">MOVIMENTO SOCIAL E CULTURAL CORES DO AMANHÃ</option>
                <option value="	MUCA - MULHERES UNIDAS DO CARATOIRA">MUCA - MULHERES UNIDAS DO CARATOIRA</option>
                <option value="	MULHERES ARRETADAS">MULHERES ARRETADAS</option>
                <option value="	MULHERES EM MOVIMENTO">MULHERES EM MOVIMENTO</option>
                <option value="	MULHERE-SE">MULHERE-SE</option>
                <option value="	MULTIPLICAR O AMOR INCLUIR O SABER">MULTIPLICAR O AMOR INCLUIR O SABER</option>
                <option value="	MUTAMB">MUTAMB</option>
                <option value="	NEAC - NÚCLEO ESPECIAL DE ATENÇÃO À CRIANÇA">NEAC - NÚCLEO ESPECIAL DE ATENÇÃO À CRIANÇA</option>
                <option value="	NEIMAR TAVARES SILVA JÚNIOR">NEIMAR TAVARES SILVA JÚNIOR</option>
                <option value="	NOS SOMOS A PONTE">NOS SOMOS A PONTE</option>
                <option value="	NOSSA RAZÃO E UMA SOCIEDADE IGUAL PARA TODOS ! QUE FAMÍLIA CARENTES TENHA DIRETO ALIMENTAÇÃO DIGNA E EDUCAÇÃO PARA TODOS PARA UM FUTURO COM MAS IGUALDADE!">NOSSA RAZÃO E UMA SOCIEDADE IGUAL PARA TODOS ! QUE FAMÍLIA CARENTES TENHA DIRETO ALIMENTAÇÃO DIGNA E EDUCAÇÃO PARA TODOS PARA UM FUTURO COM MAS IGUALDADE!</option>
                <option value="	NUCLEO DE APOIO A COMUNIDADES">NUCLEO DE APOIO A COMUNIDADES</option>
                <option value="	NÚCLEO DE APOIO AO PEQUENO CIDADÃO">NÚCLEO DE APOIO AO PEQUENO CIDADÃO</option>
                <option value="	NÚCLEO DE MULHERES DO ROSARINHO">NÚCLEO DE MULHERES DO ROSARINHO</option>
                <option value="	NUCLEO SOCIAL E PROFISSIONALIZANTE EL-SHADAY (N.S.P.E.S.)">NUCLEO SOCIAL E PROFISSIONALIZANTE EL-SHADAY (N.S.P.E.S.)</option>
                <option value="	NUCLEO SOCIO-CULTURAL SEMENTE DO AMANHA">NUCLEO SOCIO-CULTURAL SEMENTE DO AMANHA</option>
                <option value="	O BOM SAMARITANO CONTAGEM">O BOM SAMARITANO CONTAGEM</option>
                <option value="	O2 EDUCATION">O2 EDUCATION</option>
                <option value="	OBRA SOCIAL FILHOS DA RAZAO E JUSTICA">OBRA SOCIAL FILHOS DA RAZAO E JUSTICA</option>
                <option value="	OBRAS SOCIAIS DO CENTRO ESPÍRITA OBREIROS DO SENHOR">OBRAS SOCIAIS DO CENTRO ESPÍRITA OBREIROS DO SENHOR</option>
                <option value="	OBRAS SOCIAIS RAFAEL VERLANGIARI">OBRAS SOCIAIS RAFAEL VERLANGIARI</option>
                <option value="	ONE WAY - ASSISTENCIA AS CRIANCAS E ADOLESCENTES CARENTES">ONE WAY - ASSISTENCIA AS CRIANCAS E ADOLESCENTES CARENTES</option>
                <option value="	ONG ABRAÇAR FILHAS DE MARIA">ONG ABRAÇAR FILHAS DE MARIA</option>
                <option value="	ONG CASA DA VOVÓ  CHICA">ONG CASA DA VOVÓ  CHICA</option>
                <option value="	ONG CENTRO  ZUMBI DOS PALMARES">ONG CENTRO  ZUMBI DOS PALMARES</option>
                <option value="	ONG CENTRO DE TREINAMENTO DE SURF LUGAR AO SOL">ONG CENTRO DE TREINAMENTO DE SURF LUGAR AO SOL</option>
                <option value="	ONG DEUS É FIEL">ONG DEUS É FIEL</option>
                <option value="	ONG ESPERANÇA BRASIL">ONG ESPERANÇA BRASIL</option>
                <option value="	ONG HERÓIS DOS LACRES">ONG HERÓIS DOS LACRES</option>
                <option value="	ONG HUMANITARIA A FAVOR DA VIDA">ONG HUMANITARIA A FAVOR DA VIDA</option>
                <option value="	ONG INSTITUTO VITÓRIA DE SÁ">ONG INSTITUTO VITÓRIA DE SÁ</option>
                <option value="	ONG RESGATANDO A INOCENCIA">ONG RESGATANDO A INOCENCIA</option>
                <option value="	ONG UM OLHAR">ONG UM OLHAR</option>
                <option value="	ONGSOLIDARIEDADE1">ONGSOLIDARIEDADE1</option>
                <option value="	ORGANIZAÇÃO DA SOCIEDADE CIVIL IZAIAS LUZIA DA SILVA">ORGANIZAÇÃO DA SOCIEDADE CIVIL IZAIAS LUZIA DA SILVA</option>
                <option value="	ORGANIZACAO DA SOCIEDADE CIVIL JUNTOS AO LAR DE SAO FRANCISCO DE ASSIS EM SAO PAULO">ORGANIZACAO DA SOCIEDADE CIVIL JUNTOS AO LAR DE SAO FRANCISCO DE ASSIS EM SAO PAULO</option>
                <option value="	ORGANIZAÇÃO DE DESENVOLVIMENTO DO POTENCIAL HUMANO">ORGANIZAÇÃO DE DESENVOLVIMENTO DO POTENCIAL HUMANO</option>
                <option value="	ORGANIZACAO ECO-SOCIAL AGUA AZUL">ORGANIZACAO ECO-SOCIAL AGUA AZUL</option>
                <option value="	ORGANIZAÇÃO LIBERTARIOS DO CAPÃO REDONDO">ORGANIZAÇÃO LIBERTARIOS DO CAPÃO REDONDO</option>
                <option value="	ORGANIZAÇÃO NOVOS HERDEIROS HUMANÍSTICOS">ORGANIZAÇÃO NOVOS HERDEIROS HUMANÍSTICOS</option>
                <option value="	ORGANIZACAO SOCIAL IDENTIDADE PERIFERICA">ORGANIZACAO SOCIAL IDENTIDADE PERIFERICA</option>
                <option value="	ORGANIZACAO SOCIAL VOKUIM">ORGANIZACAO SOCIAL VOKUIM</option>
                <option value="	OS FILHOS DE MAE LUIZA">OS FILHOS DE MAE LUIZA</option>
                <option value="	OSC CRESCE COMUNIDADE">OSC CRESCE COMUNIDADE</option>
                <option value="	OSC PONTO SOCIAL">OSC PONTO SOCIAL</option>
                <option value="	OSCIP JOVEM SERTÃO">OSCIP JOVEM SERTÃO</option>
                <option value="	OSCIP VILLAGE MANA DA ESPERANCA">OSCIP VILLAGE MANA DA ESPERANCA</option>
                <option value="	PALAVRAS ENCANTADAS">PALAVRAS ENCANTADAS</option>
                <option value="	PÃO COM CAFÉ">PÃO COM CAFÉ</option>
                <option value="	PAPO SOLIDÁRIO">PAPO SOLIDÁRIO</option>
                <option value="	PATRONATO JUVENIL GARCENSE">PATRONATO JUVENIL GARCENSE</option>
                <option value="	PATRONATO SANTANA">PATRONATO SANTANA</option>
                <option value="	PENSANDO BEM">PENSANDO BEM</option>
                <option value="	PEPE - MASSANGANA">PEPE - MASSANGANA</option>
                <option value="	PEQUENO SAMURAI">PEQUENO SAMURAI</option>
                <option value="	PERALTA">PERALTA</option>
                <option value="	PEREIRINHA FUTEBOL CLUBE">PEREIRINHA FUTEBOL CLUBE</option>
                <option value="	PINTANDO 7 SOLIDÁRIO">PINTANDO 7 SOLIDÁRIO</option>
                <option value="	PLOJETO SOCIAL SIM EU SOU DE SANTA">PLOJETO SOCIAL SIM EU SOU DE SANTA</option>
                <option value="	PONTINCLUSIVA">PONTINCLUSIVA</option>
                <option value="	POTIGUARES">POTIGUARES</option>
                <option value="	PRETAS RUAS">PRETAS RUAS</option>
                <option value="	PRÓ-AÇÃO DE APOIO A EDUCAÇÃO E CIDADANIA">PRÓ-AÇÃO DE APOIO A EDUCAÇÃO E CIDADANIA</option>
                <option value="	PROGRAMA DE ASSISTÊNCIA AS CRIANÇAS E ADOLESCENTES">PROGRAMA DE ASSISTÊNCIA AS CRIANÇAS E ADOLESCENTES</option>
                <option value="	PROGRAMA SOCIAL SIM! EU SOU DO MEIO (SESM)">PROGRAMA SOCIAL SIM! EU SOU DO MEIO (SESM)</option>
                <option value="	PROHUMANO - DELEGACIA DE DIREITOS HUMANOS">PROHUMANO - DELEGACIA DE DIREITOS HUMANOS</option>
                <option value="	PROJETO ABRAÇO AMIGAS DO BEM">PROJETO ABRAÇO AMIGAS DO BEM</option>
                <option value="	PROJETO ACERTE O ALVO PARA VIVER">PROJETO ACERTE O ALVO PARA VIVER</option>
                <option value="	PROJETO ACOLHER DE PARNAÍBA">PROJETO ACOLHER DE PARNAÍBA</option>
                <option value="	PROJETO AMOR AQUEM">PROJETO AMOR AQUEM</option>
                <option value="	PROJETO AMOR E ESPERANÇA/ ASSOCIAÇÃO CONSTRUINDO A CIDADANIA">PROJETO AMOR E ESPERANÇA/ ASSOCIAÇÃO CONSTRUINDO A CIDADANIA</option>
                <option value="	PROJETO ASSISTENCIAL SEMENTES DE ESPERANÇA">PROJETO ASSISTENCIAL SEMENTES DE ESPERANÇA</option>
                <option value="	PROJETO AURORA">PROJETO AURORA</option>
                <option value="	PROJETO AVANTE">PROJETO AVANTE</option>
                <option value="	PROJETO AVELINO">PROJETO AVELINO</option>
                <option value="	PROJETO BAYERNZINHO">PROJETO BAYERNZINHO</option>
                <option value="	PROJETO BODYBOARD PARÁ">PROJETO BODYBOARD PARÁ</option>
                <option value="	PROJETO BREJAL">PROJETO BREJAL</option>
                <option value="	PROJETO BRIGADA SOLIDÁRIA">PROJETO BRIGADA SOLIDÁRIA</option>
                <option value="	PROJETO CABE +1">PROJETO CABE +1</option>
                <option value="	PROJETO CICLO SOLIDÁRIO">PROJETO CICLO SOLIDÁRIO</option>
                <option value="	PROJETO COMUNITÁRIO SORRISO DA CRIANÇA">PROJETO COMUNITÁRIO SORRISO DA CRIANÇA</option>
                <option value="	PROJETO CONEXAO VIDA">PROJETO CONEXAO VIDA</option>
                <option value="	PROJETO CORRENTE DO BEM">PROJETO CORRENTE DO BEM</option>
                <option value="	PROJETO CRESCENDO COM CRISTO">PROJETO CRESCENDO COM CRISTO</option>
                <option value="	PROJETO CRIANÇA CONTENTE">PROJETO CRIANÇA CONTENTE</option>
                <option value="	PROJETO CRIANÇA FELIZ ITAQUERA">PROJETO CRIANÇA FELIZ ITAQUERA</option>
                <option value="	PROJETO CULTURA E CIDADANIA LEO DO PIFE">PROJETO CULTURA E CIDADANIA LEO DO PIFE</option>
                <option value="	PROJETO DE DESENVOLVIMENTO CULTURAL ARTE E COR">PROJETO DE DESENVOLVIMENTO CULTURAL ARTE E COR</option>
                <option value="	PROJETO DESAFIANDO GIGANTES">PROJETO DESAFIANDO GIGANTES</option>
                <option value="	PROJETO DOS AMIGOS DOAR FAZ BEM">PROJETO DOS AMIGOS DOAR FAZ BEM</option>
                <option value="	PROJETO EDUCA SURF SOCIAL">PROJETO EDUCA SURF SOCIAL</option>
                <option value="	PROJETO EFRAIM - BA">PROJETO EFRAIM - BA</option>
                <option value="	PROJETO EFRAIM - RJ">PROJETO EFRAIM - RJ</option>
                <option value="	PROJETO ESPALHE CESTAS">PROJETO ESPALHE CESTAS</option>
                <option value="	PROJETO ESPERANÇA DO GOGÓ">PROJETO ESPERANÇA DO GOGÓ</option>
                <option value="	PROJETO ESTRELA DO DESERTO">PROJETO ESTRELA DO DESERTO</option>
                <option value="	PROJETO FÁBRICA">PROJETO FÁBRICA</option>
                <option value="	PROJETO FILADELFIA">PROJETO FILADELFIA</option>
                <option value="	PROJETO FLOR DE LÓTUS">PROJETO FLOR DE LÓTUS</option>
                <option value="	PROJETO FLORES DO BEM">PROJETO FLORES DO BEM</option>
                <option value="	PROJETO FOME DE QUÊ?">PROJETO FOME DE QUÊ?</option>
                <option value="	PROJETO FORÇA JOVEM">PROJETO FORÇA JOVEM</option>
                <option value="	PROJETO IDE COM AMOR">PROJETO IDE COM AMOR</option>
                <option value="	PROJETO JOVENS EM AÇÃO">PROJETO JOVENS EM AÇÃO</option>
                <option value="	PROJETO MILAGRES">PROJETO MILAGRES</option>
                <option value="	PROJETO MOURÃO ELES NÃO SÃO INVISIVEIS">PROJETO MOURÃO ELES NÃO SÃO INVISIVEIS</option>
                <option value="	PROJETO NOVA CHANCE">PROJETO NOVA CHANCE</option>
                <option value="	PROJETO NOVA HISTÓRIA/REDE DE MULHERES GIRASSOL">PROJETO NOVA HISTÓRIA/REDE DE MULHERES GIRASSOL</option>
                <option value="	PROJETO NOVA VIDA">PROJETO NOVA VIDA</option>
                <option value="	PROJETO NOVO AMANHECER">PROJETO NOVO AMANHECER</option>
                <option value="	PROJETO ONDAS">PROJETO ONDAS</option>
                <option value="	PROJETO OUSADIA">PROJETO OUSADIA</option>
                <option value="	PROJETO PELA RUA PELO REINO">PROJETO PELA RUA PELO REINO</option>
                <option value="	PROJETO PEQUENINOS PARA CRISTO">PROJETO PEQUENINOS PARA CRISTO</option>
                <option value="	PROJETO PPL - PEDREIRA PRADO LOPES">PROJETO PPL - PEDREIRA PRADO LOPES</option>
                <option value="	PROJETO QUEIRA BEM">PROJETO QUEIRA BEM</option>
                <option value="	PROJETO QUERO AJUDAR">PROJETO QUERO AJUDAR</option>
                <option value="	PROJETO RECRIANDO RAIZES">PROJETO RECRIANDO RAIZES</option>
                <option value="	PROJETO RESGATE UMA VIDA">PROJETO RESGATE UMA VIDA</option>
                <option value="	PROJETO SAUDE NOS BAIRROS">PROJETO SAUDE NOS BAIRROS</option>
                <option value="	PROJETO SEMEAR">PROJETO SEMEAR</option>
                <option value="	PROJETO SEMENTES DA FÉ">PROJETO SEMENTES DA FÉ</option>
                <option value="	PROJETO SERVENTINA">PROJETO SERVENTINA</option>
                <option value="	PROJETO SHALOM">PROJETO SHALOM</option>
                <option value="	PROJETO SITIO CIPÓ">PROJETO SITIO CIPÓ</option>
                <option value="	PROJETO SOCIAL">PROJETO SOCIAL</option>
                <option value="	PROJETO SOCIAL E CULTURAL REGATY">PROJETO SOCIAL E CULTURAL REGATY</option>
                <option value="	PROJETO SOCIAL ENTRANDO NA LINHA">PROJETO SOCIAL ENTRANDO NA LINHA</option>
                <option value="	PROJETO SOCIAL FAZER O BEM FAZ BEM A VIDA">PROJETO SOCIAL FAZER O BEM FAZ BEM A VIDA</option>
                <option value="	PROJETO SOCIAL FILHOS DA INCLUSÃO">PROJETO SOCIAL FILHOS DA INCLUSÃO</option>
                <option value="	PROJETO SOCIAL INDIO GLADIADOR PALMEIRAS-BA">PROJETO SOCIAL INDIO GLADIADOR PALMEIRAS-BA</option>
                <option value="	PROJETO SOCIAL MANASSU , QUAL O MUNDO QUE EU QUERO?">PROJETO SOCIAL MANASSU , QUAL O MUNDO QUE EU QUERO?</option>
                <option value="	PROJETO SOCIAL MÃOS NA COMUNIDADE">PROJETO SOCIAL MÃOS NA COMUNIDADE</option>
                <option value="	PROJETO SOCIAL PATRULHA DO BEM">PROJETO SOCIAL PATRULHA DO BEM</option>
                <option value="	PROJETO SOCIAL RESTAURAR">PROJETO SOCIAL RESTAURAR</option>
                <option value="	PROJETO SOCIAL SIM! EU SOU DE SANTA TEREZA">PROJETO SOCIAL SIM! EU SOU DE SANTA TEREZA</option>
                <option value="	PROJETO SOCIAL SÓ JESUS SALVA">PROJETO SOCIAL SÓ JESUS SALVA</option>
                <option value="	PROJETO SOCIOCULTURAL AMIGOS DO JOAO XX">PROJETO SOCIOCULTURAL AMIGOS DO JOAO XX</option>
                <option value="	PROJETO SOLIDÁRIO MARIA LUIZA">PROJETO SOLIDÁRIO MARIA LUIZA</option>
                <option value="	PROJETO SOLIDÁRIOS">PROJETO SOLIDÁRIOS</option>
                <option value="	PROJETO SONHAR E AÇÃO">PROJETO SONHAR E AÇÃO</option>
                <option value="	PROJETO SONHAR E FAZER ACONTECER">PROJETO SONHAR E FAZER ACONTECER</option>
                <option value="	PROJETO SONHART">PROJETO SONHART</option>
                <option value="	PROJETO SURF DO BEM">PROJETO SURF DO BEM</option>
                <option value="	PROJETO TAMO JUNTO">PROJETO TAMO JUNTO</option>
                <option value="	PROJETO TERNURA">PROJETO TERNURA</option>
                <option value="	PROJETO TIJOLINHO">PROJETO TIJOLINHO</option>
                <option value="	PROJETO TRANSFORMAÇÃO - PE">PROJETO TRANSFORMAÇÃO - PE</option>
                <option value="	PROJETO TRANSFORMAÇÃO - SP">PROJETO TRANSFORMAÇÃO - SP</option>
                <option value="	PROJETO TRANSFORMAR">PROJETO TRANSFORMAR</option>
                <option value="	PROJETO UNIÃO PARQUE">PROJETO UNIÃO PARQUE</option>
                <option value="	PROJETO VIDA EMANUEL">PROJETO VIDA EMANUEL</option>
                <option value="	PROJETO VIDANÇAR">PROJETO VIDANÇAR</option>
                <option value="	PROJETO VIELA">PROJETO VIELA</option>
                <option value="	PROJETO ZOE">PROJETO ZOE</option>
                <option value="	PROJETOCRIANCAFELIZDOPV">PROJETOCRIANCAFELIZDOPV</option>
                <option value="	PROJETOS AMIGOS DAS CRIANÇAS">PROJETOS AMIGOS DAS CRIANÇAS</option>
                <option value="	PROJETRA">PROJETRA</option>
                <option value="	PROVIDENCIANDO A FAVOR DA VIDA">PROVIDENCIANDO A FAVOR DA VIDA</option>
                <option value="	PURO AMOR">PURO AMOR</option>
                <option value="	QUEIRA O BEM">QUEIRA O BEM</option>
                <option value="	QUERUBINS CRIANÇAS VIVAS E SEM DROGAS">QUERUBINS CRIANÇAS VIVAS E SEM DROGAS</option>
                <option value="	QUILOMBO DA TUCA">QUILOMBO DA TUCA</option>
                <option value="	RECICLANDO VIDAS">RECICLANDO VIDAS</option>
                <option value="	RECOMEÇAR">RECOMEÇAR</option>
                <option value="	RECRIANDO SORRISOS">RECRIANDO SORRISOS</option>
                <option value="	REDE ABLAN">REDE ABLAN</option>
                <option value="	REDE MULTILATERAL">REDE MULTILATERAL</option>
                <option value="	REDE POSTINHO DE SAÚDE ORGANIZAÇÃO DE SAÚDE PREVENTIVA MULTIDISCIPLINAR SOCIAL">REDE POSTINHO DE SAÚDE ORGANIZAÇÃO DE SAÚDE PREVENTIVA MULTIDISCIPLINAR SOCIAL</option>
                <option value="	REDE PROTAGONISTA">REDE PROTAGONISTA</option>
                <option value="	REINTEGRA RECIFE">REINTEGRA RECIFE</option>
                <option value="	REMES">REMES</option>
                <option value="	RESILIENTE SLUM">RESILIENTE SLUM</option>
                <option value="	RETURN PROJECTS">RETURN PROJECTS</option>
                <option value="	REVIVER CENTRO DE INTEGRACAO DA CRIANCA E DO ADOLESCENTE-(RECICA)">REVIVER CENTRO DE INTEGRACAO DA CRIANCA E DO ADOLESCENTE-(RECICA)</option>
                <option value="	RONGO-RJ - ASSOCIAÇÃO REGIÃO DE OFICINA NACIONAL DE GRAFITE ORGANIZADO DO RIO DE JANEIRO">RONGO-RJ - ASSOCIAÇÃO REGIÃO DE OFICINA NACIONAL DE GRAFITE ORGANIZADO DO RIO DE JANEIRO</option>
                <option value="	SANTA CRUZ PONTA DA SERRA">SANTA CRUZ PONTA DA SERRA</option>
                <option value="	SEBASTIÃO EDEMYR E. LEITE-ME">SEBASTIÃO EDEMYR E. LEITE-ME</option>
                <option value="	SEGUNDA CHANCE - INSTITUTO CRISTAO DE AUXILIO AOS NECESSITADOS">SEGUNDA CHANCE - INSTITUTO CRISTAO DE AUXILIO AOS NECESSITADOS</option>
                <option value="	SEMEART">SEMEART</option>
                <option value="	SEMENTE PROFISSIONAL">SEMENTE PROFISSIONAL</option>
                <option value="	SEMENTES DA FE">SEMENTES DA FE</option>
                <option value="	SEMPRE CRIANÇA">SEMPRE CRIANÇA</option>
                <option value="	SERVINDO AMOR">SERVINDO AMOR</option>
                <option value="	SOBREVIVENTES DA PERIFERIA">SOBREVIVENTES DA PERIFERIA</option>
                <option value="	SOCIEDADE BENEFICIENTE ISRAELITA BRASILEIRA TALMUD THORA">SOCIEDADE BENEFICIENTE ISRAELITA BRASILEIRA TALMUD THORA</option>
                <option value="	SOCIEDADE CULTURAL PROJETO LUAR">SOCIEDADE CULTURAL PROJETO LUAR</option>
                <option value="	SOCIEDADE ESPÍRITA TRABALHO E ESPERANÇA">SOCIEDADE ESPÍRITA TRABALHO E ESPERANÇA</option>
                <option value="	SOCIEDADE UNIÃO DA VILA DOS EUCALIPTOS - SUVE">SOCIEDADE UNIÃO DA VILA DOS EUCALIPTOS - SUVE</option>
                <option value="	SOFTWARE E PROCESSOS LTDA">SOFTWARE E PROCESSOS LTDA</option>
                <option value="	SOMOS TODOS MURIBECA - STM">SOMOS TODOS MURIBECA - STM</option>
                <option value="	SOSSAOJOSE">SOSSAOJOSE</option>
                <option value="	SOU MAIS JF">SOU MAIS JF</option>
                <option value="	SPARTA ASSOCIAÇÃO ESPORTIVA DO MORRO DA PROVIDÊNCIA">SPARTA ASSOCIAÇÃO ESPORTIVA DO MORRO DA PROVIDÊNCIA</option>
                <option value="	SUBÚRBIO EM AÇÃO">SUBÚRBIO EM AÇÃO</option>
                <option value="	TEATRO ESCOLA SERTÃO VIVO">TEATRO ESCOLA SERTÃO VIVO</option>
                <option value="	TECH GIRLS">TECH GIRLS</option>
                <option value="	TERERÊ KIDS PROJECT">TERERÊ KIDS PROJECT</option>
                <option value="	TEREZINHA OSMARI BAGATINI">TEREZINHA OSMARI BAGATINI</option>
                <option value="	TERNO MOÇAMBIQUE ESTRELA GUIA">TERNO MOÇAMBIQUE ESTRELA GUIA</option>
                <option value="	THAIS MONIQUE GERVÁSIO DE MORAES WANDERLEY">THAIS MONIQUE GERVÁSIO DE MORAES WANDERLEY</option>
                <option value="	TOP CLUBE DE XADREZ">TOP CLUBE DE XADREZ</option>
                <option value="	TRANSFORMADO VIDAS E MUDANDO A SOCIEDADE! PRINCÍPIOS BÍBLICOS / EDUCAÇÃO / CULTURA / ESPORTE / QUALIFICAÇÃO/ INOVAÇÃO /TECNOLOGIA /">TRANSFORMADO VIDAS E MUDANDO A SOCIEDADE! PRINCÍPIOS BÍBLICOS / EDUCAÇÃO / CULTURA / ESPORTE / QUALIFICAÇÃO/ INOVAÇÃO /TECNOLOGIA /</option>
                <option value="	TRANSFORMAR">TRANSFORMAR</option>
                <option value="	TRAZEMOS SORRISOS">TRAZEMOS SORRISOS</option>
                <option value="	TRAZFAVELA ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL LTDA">TRAZFAVELA ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL LTDA</option>
                <option value="	TURMA DO SAPO">TURMA DO SAPO</option>
                <option value="	TUTTI ALLEGRO - COMPANHIA DE ARTE">TUTTI ALLEGRO - COMPANHIA DE ARTE</option>
                <option value="	TV PERIFERIA EM FOCO">TV PERIFERIA EM FOCO</option>
                <option value="	UNIAO BEM ESTAR - UBE">UNIAO BEM ESTAR - UBE</option>
                <option value="	UNIAO COMUNITARIA DE DESENVOLVIMENTO DE FREXEIRAS">UNIAO COMUNITARIA DE DESENVOLVIMENTO DE FREXEIRAS</option>
                <option value="	UNIAO DOS MORADORES DO TURU">UNIAO DOS MORADORES DO TURU</option>
                <option value="	UNIAO DOS MORADORES E COMERCIANTES DO SAO CAETANO">UNIAO DOS MORADORES E COMERCIANTES DO SAO CAETANO</option>
                <option value="	UNIÃO MARCIAL MAUÁ">UNIÃO MARCIAL MAUÁ</option>
                <option value="	UNIÃO POPULAR PELA VIDA">UNIÃO POPULAR PELA VIDA</option>
                <option value="	UNIDAS PELO BEM">UNIDAS PELO BEM</option>
                <option value="	UNIFICAÇÃO DAS QUEBRADAS">UNIFICAÇÃO DAS QUEBRADAS</option>
                <option value="	UNIVERSUS ESC ART POD ART CULTURAL">UNIVERSUS ESC ART POD ART CULTURAL</option>
                <option value="	VALDENEY SILVA NEVES">VALDENEY SILVA NEVES</option>
                <option value="	VELHO CHICO ATIVO">VELHO CHICO ATIVO</option>
                <option value="	VEM SER FUTSAL">VEM SER FUTSAL</option>
                <option value="	VEMSER">VEMSER</option>
                <option value="	VEMSERFUTSAL">VEMSERFUTSAL</option>
                <option value="	VIADUTO DAS ARTES">VIADUTO DAS ARTES</option>
                <option value="	VIDAS E VALORES">VIDAS E VALORES</option>
                <option value="	VIDAS PRECIOSAS">VIDAS PRECIOSAS</option>
                <option value="	VILA CARIOCA FERTIL">VILA CARIOCA FERTIL</option>
                <option value="	VILA EM PROGRESSO">VILA EM PROGRESSO</option>
                <option value="	VOLUNTÁRIOS FAZENDO CRIANÇA SORRIR">VOLUNTÁRIOS FAZENDO CRIANÇA SORRIR</option>
                <option value="	VOZ NAS COMUNIDADES">VOZ NAS COMUNIDADES</option>
                <option value="	VOZES DAS PERIFERIAS">VOZES DAS PERIFERIAS</option>
                <option value="	WATER IS LIFE BRASIL">WATER IS LIFE BRASIL</option>
                <option value="	WBIRACY PAIS DA COSTA">WBIRACY PAIS DA COSTA</option>
                <option value="	ZHALUART INSTITUTO">ZHALUART INSTITUTO</option>
              </select>
            </div>
            {/* Nome */}

            <div className="field">
              <label htmlFor="text" className="nome">
                {' '}
                Nome<span className="required">*</span>
              </label>
              <input
                required
                type="text"
                className="nome borda"
                id="nome"
                placeholder="Nome Completo"
                value={nome}
                onChange={e => setNome(e.target.value)}
              />
            </div>

            <div className="field">
              <label htmlFor="text" className="">
                {' '}
                Data de Nascimento<span className="required">*</span>
              </label>
              <input
                required
                type="date"
                className="borda"
                id="dataAniversario"
                placeholder="DD/MM/YYYY"
                value={dataAniversario}
                onChange={e => setDataAniversario(e.target.value)}
              />
            </div>

            <div className="field">
              <label htmlFor="text" className="cpf">
                {' '}
                CPF<span className="required">*</span>
              </label>
              <input
                required
                type="number"
                className="cpf borda"
                id="cpf"
                placeholder="XXXXXXXXXXX"
                value={cpf}
                onChange={e => setCpf(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="text" className="email">
                {' '}
                Email
              </label>
              <input
                type="text"
                className="email borda"
                id="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="text" className="telefone">
                {' '}
                Telefone
              </label>
              <input
                type="number"
                className="telefone borda"
                id="telefone"
                placeholder="DDD XXXXX-XXXX"
                value={telefone}
                onChange={e => setTelefone(e.target.value)}
              />
            </div>

            <div className="field">
              <label htmlFor="">
                Nacionalidade<span className="required">*</span>
              </label>
              <select
                required
                className="borda"
                id="nacionalidade"
                name="nacionalidade"
                value={nacionalidade}
                onChange={e => setNacionalidade(e.target.value)}
              >
                {' '}
                <option value="brasil">Brasil</option>
              </select>
            </div>

            <div className="field">
              <label htmlFor="">
                Gênero<span className="required">*</span>
              </label>
              <select
                required
                className="borda"
                id="genero"
                name="genero"
                value={genero}
                onChange={e => setGenero(e.target.value)}
              >
                {' '}
                <option></option>
                <option value="Nao importa">Prefiro não informar </option>
                <option value="Masculino">Masculino </option>
                <option value="Feminino">Feminino </option>
                <option value="Neutro">Neutro </option>
              </select>
            </div>

            <div className="field">
              <label htmlFor="">
                Tipo de Contrato<span className="required">*</span>
              </label>
              <select
                required
                className="borda"
                id="tipoDeContrato"
                name="tipoDeContrato"
                value={tipoDeContrato}
                onChange={e => setTipoDeContrato(e.target.value)}
              >
                {' '}
                <option></option>
                <option value="CLT">CLT </option>
                <option value="Jovem Aprendiz">Jovem Aprendiz </option>
                <option value="Estágio">Estágio </option>
                <option value="CLT ou Jovem Aprendiz">
                  CLT ou Jovem Aprendiz{' '}
                </option>
                <option value="CLT, Estágio ou Jovem Aprendiz">
                  CLT, Estágio ou Jovem Aprendiz{' '}
                </option>
                <option value="Estágio ou Jovem Aprendiz">
                  Estágio ou Jovem Aprendiz{' '}
                </option>
                <option value="CLT ou Estágio">CLT ou Estágio </option>
                <option value="MEI">MEI </option>
                <option value="Freelancer">Freelancer </option>
                <option value="Autônomo">Autônomo </option>
                <option value="Empreendedor(a)">Empreendedor(a) </option>
              </select>
            </div>

            <div className="field">
              <label htmlFor="">
                Nível de Formação<span className="required">*</span>
              </label>

              <select
                required
                className="borda"
                id="nivelDeFormacao"
                name="nivelDeFormacao"
                value={nivelDeFormacao}
                onChange={e => setNivelDeFormacao(e.target.value)}
              >
                <option></option>
                <option value="Ensino Fundamental Incompleto">
                  {' '}
                  Ensino Fundamental Incompleto
                </option>
                <option value="Ensino Fundamental Completo">
                  {' '}
                  Ensino Fundamental Completo{' '}
                </option>
                <option value="Ensino Médio Incompleto">
                  {' '}
                  Ensino Médio Incompleto{' '}
                </option>
                <option value="Ensino Médio Completo">
                  {' '}
                  Ensino Médio Completo{' '}
                </option>
                <option value="Ensino Superior Incompleto (em andamento)">
                  {' '}
                  Ensino Superior Incompleto (em andamento){' '}
                </option>
                <option value="Ensino Superior Completo">
                  {' '}
                  Ensino Superior Completo{' '}
                </option>
                <option value="Não Alfabetizado">Não Alfabetizado </option>
              </select>
            </div>

            <div className="field">
              <label htmlFor="">
                Participou de Algum Curso de Qualificação Profissional na ONG?
                <span className="required">*</span>
              </label>
              <select
                required
                className="borda"
                id="qualificacao"
                name="qualificacao"
                value={qualificacao}
                onChange={e => setQualificacao(e.target.value)}
              >
                <option></option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
              </select>
            </div>
            <div className="field">
              <label htmlFor="text" className="empresa">
                {' '}
                Empresa Contratante<span className="required">*</span>
              </label>
              <input
                required
                type="text"
                className="empresa borda"
                id="empresa"
                placeholder="Empresa"
                value={empresa}
                onChange={e => setEmpresa(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="text" className="areaDeAtuacao">
                {' '}
                Área de Atuação da Empresa<span className="required">*</span>
              </label>
              <input
                required
                type="text"
                className="areaDeAtuacao borda"
                id="areaDeAtuacao"
                placeholder="Área de Atuação"
                value={areaDeAtuacao}
                onChange={e => setAreaDeAtuacao(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="text" className="cargo">
                {' '}
                Cargo<span className="required">*</span>
              </label>
              <input
                required
                type="text"
                className="cargo borda"
                id="cargo"
                placeholder="Cargo"
                value={cargo}
                onChange={e => setCargo(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="text" className="faixaSalarial">
                {' '}
                Faixa Salárial<span className="required">*</span>
              </label>

              <select
                required
                placeholder="Faixa Salárial"
                className="faixaSalarial borda"
                id="faixaSalarial"
                value={faixaSalarial}
                onChange={e => setFaixaSalarial(e.target.value)}
              >
                <option>à combinar</option>
                <option>600 - 700</option>
                <option>700 - 800</option>
                <option>800 - 900</option>
                <option>900 - 1000</option>
                <option>1000 - 1500</option>
                <option>1500 - 2000</option>
                <option>2000 - 2500</option>
                <option>2500 - 3000</option>
                <option>3000 - 3500</option>
                <option>3500 - 4000</option>
                <option>4000 - 4500</option>
                <option>4500 - 5000</option>
                <option>5000 - 5500</option>
                <option>5500 - 6000</option>
                <option>6000 - 7000</option>
                <option>7000 - 8000</option>
                <option>8000 - 9000</option>
                <option>9000 - 10000</option>
                <option>10000 - 10000</option>
              </select>
              <div className="action">
                <button type="submit">Enviar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Home
