// import logo from './logo.svg';
import './App.css'
import Rotas from './Rotas'

function App() {
  return (
    <Rotas />
    // <div className="App">
    //   <h1>ola</h1>
    // </div>
  )
}

export default App
