import { useState, useEffect } from 'react'
import api from '../../services/api'
import Header from '../../components/Header'
import './Lista.css'
import Post from '../../components/Lista'
import Pagination from '../../components/Lista'

const Lista = () => {
  return (
    <>
      <Header />
      <main className="container-lista">
        <Pagination />
      </main>
    </>
  )
}

export default Lista
