
import './Header.css';
import { Link } from "react-router-dom";
function Header() {

  return(
    <header className="header">
      <nav className="menu">
        <div className="menu">
          <img src="https://secureservercdn.net/72.167.241.46/enm.f48.myftpupload.com/wp-content/uploads/2021/10/logo-e1637258781450.png?time=1650636341" alt="" />
        </div>

        <h4 className="menuItem"><a href="https://conectatrampo.com/dash/">DashBoard</a></h4>
        <h4 className="menuItem"><a href="https://conectatrampo.com/meu-painel-aluno/">Painel Aluno</a></h4>
        <h4 className="menuItem"><a href="https://conectatrampo.com/meu-painel-empresa/">Painel Empresa</a></h4>
        <Link to="/">
          <h4 className="menuItem"><a href="/">Adicionar Contratado</a></h4>
        </Link>
        <Link to="/lista">
          <h4 className="menuItem">Histórico</h4>
        </Link>
        <h4> </h4>
        
      </nav>

    </header>
  )
}

export default Header